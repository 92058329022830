import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';
import { Formulario } from 'src/app/_models';
import { TipoFormulario } from 'src/app/_enums/TipoFormulario';

@Component({
  selector: 'app-vendas-chat',
  templateUrl: './vendas-chat.component.html'
})
export class VendasChatComponent implements OnInit {

  public form = new Formulario();
  public tipoMenuValue: number = TipoMenu.Checkup;

  constructor() {
    this.definirProprForm();
   }

  ngOnInit() {
  }

  private definirProprForm(): void {
    this.form.id = TipoFormulario.VendasChat;
    this.form.link = TipoFormulario.VendasChatRouterLink;
    this.form.descricao = TipoFormulario.VendasChatDescription;

    localStorage.setItem('currentForm', JSON.stringify(this.form));
  }

}