﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertService } from '../_services/alert.service';
import {throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { MatDialog } from '@angular/material';
import { PopupMessagesComponent } from '../_components/popup-messages/popup-messages.component';
import { Message } from '../_models/Message';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthenticationService {
    private apiUrl: string;
    private messageModel: Message;

    constructor(private http: HttpClient, private alertService: AlertService, private dialog?: MatDialog) { }

    login(username: string, password: string) {

        //this.apiUrl = `${environment.apiUrl}/api/Token?Username=${username}&Password=${password}`;//, { username: username, password: password })
        this.apiUrl = `${environment.apiUrl}/api/Token`;
        let hdrs = new HttpHeaders({ 'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT' });
        
        return this.http.post<any>(this.apiUrl, {Username: username, Password: password }, { headers: hdrs })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.accessToken) {
                    const helper = new JwtHelperService();
                    const decodedToken = helper.decodeToken(user.accessToken);
                    let token = user.accessToken;
                    let autentica = user.authenticated;

                    user = decodedToken;
                    user.authenticated = autentica;
                    user.accessToken = token;
                    user.usuario = JSON.parse(user.usuario);
                    user.email = user.usuario.email;

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
                else{
                    this.messageModel = new Message(
                        "Problema ao realizar o login",
                        "Usuário ou senha incorretos",
                        "",
                        "error");
                    this.dialog.open(PopupMessagesComponent, { data: this.messageModel });
                    
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}