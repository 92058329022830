import { RegistroCadastro } from '../_models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'
import { RegistroCadastroInsert } from '../_models/RegistroCadastroInsert';
import { CommandResult } from '../_models/CommandResult';

@Injectable({
    providedIn: 'root'
})

export class RegistroCadastroService {

    getRegistroCadastro(coletores: RegistroCadastroInsert): Observable<any> {
        return this.http.post<CommandResult>(`${environment.apiUrl}/RegistroCadastro/Post`, coletores);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) { }
}
