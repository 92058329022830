﻿export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    login: string;
    email: string;
    accessToken: string;
    visualizarProtocoloTerceiros: boolean;
    movimentarProtocoloTerceiros : boolean;
}