import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';

@Component({
  selector: 'relatorios',
  templateUrl: './relatorios.component.html'
})
export class RelatoriosComponent implements OnInit {

  public tipoMenuValue: number = TipoMenu.Checkup;

  constructor() { }

  ngOnInit() {
  }

}