import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from '../alert.service';

@Injectable()
export abstract class DataService {
    readonly config: ConfigService;
    abstract context: string;

    constructor(protected http: HttpClient, private alertService: AlertService) {
        this.config = new ConfigService();
    }

    protected get<T>(url?: string, resourceId?: number | string): Observable<T> {
        const apiUrl = this.config.apiUrl + '/' + this.context + (url ? '/' + url : '') + (resourceId ? '/' + resourceId : '');
        return this.http.get<T>(apiUrl);
    }

    protected getWithParams<T>(url?: string, params?: HttpParams | { [param: string]: string | string[] }): Observable<T> {
        const apiurl = this.config.apiUrl + '/' + this.context + '/' + (url ? url : '');
        return this.http.get<T>(apiurl, { params });
    }

    protected post<T>(url: string, data: any): Observable<T> {
        const headers = new HttpHeaders({
            'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT'
        });

        const options = {
                headers: headers
        };

        return this.http.post<T>(this.config.apiUrl + '/' + this.context + '/' + (url ? url : ''), data, options);
    }

    protected put<T>(url: string, data: any): Observable<T> {
        const headers = new HttpHeaders({
            'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT'
        });

        const options = {
                headers: headers
        };

        return this.http.put<T>(this.config.apiUrl + '/' + this.context + '/' + (url ? url : ''), data, options);
    }

    protected delete<T>(url?: string, resourceId?: number | string): Observable<T> {
        const headers = new HttpHeaders({
            'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT'
        });

        const options = {
                headers: headers
        };

        const end = this.config.apiUrl + '/' + this.context + (url ? '/' + url : '') + '/' + resourceId;
        return this.http.delete<T>(end, options);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            console.log(`${operation} failed: ${error.message}`);

            this.alertService.error(`Ocorreu um problema ao verificar aempresa MRV. Detalhes: \n ${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
