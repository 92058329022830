import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Message } from '../../_models';

@Component({
  selector: 'app-popup-messages',
  templateUrl: './popup-messages.component.html',
  styleUrls: ['./popup-messages.component.css']
})
export class PopupMessagesComponent implements OnInit {
  textButton: string;
  confirm: boolean;
  constructor(private dialogRef: MatDialogRef<PopupMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Message) { }

  ngOnInit() {
    if (this.data.type == "confirm"){
      this.confirm = true;
      this.textButton = "SIM";
    }
    else{
      this.confirm = false;
      this.textButton = "OK";
    }
      
  }

  closePopup() {
    this.dialogRef.close(true);
  }

  notConfirm() {
    this.dialogRef.close(false);
  }

}
