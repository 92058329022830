﻿
import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { GraficoMelhorAvaliacaoComponent } from '../melhor-avaliacao/melhor-avaliacao.component';
import { MenuGraficoComponent } from '../menu-grafico/menu-grafico.component';
import { ToastrService } from 'ngx-toastr';
import { GraficoService } from 'src/app/_services/grafico.service';
import { AlertService } from '../../../../_services';
import { DatePipe } from '@angular/common';
import { GraficoMelhorAvaliacao } from 'src/app/_models/GraficoMelhorAvaliacao';
import { GraficoVolumetriaAvaliacaoComponent } from '../volumetria-avaliacao/volumetria-avaliacao.component';
import { GraficoVolumetriaAvaliacao } from 'src/app/_models/GraficoVolumetriaAvaliacao';
import { GraficoMetaRealizadoPrevistoComponent } from '../meta-realizado-previsto/meta-realizado-previsto.component';
import { GraficoMetaRealizadoPrevisto } from 'src/app/_models/GraficoMetaRealizadoPrevisto';
import { ModeloGrafico } from 'src/app/_enums/ModeloGrafico';
import { GraficoVolumetriaTipoCheckupComponent } from '../volumetria-tipo-checkup/volumetria-tipo-checkup.component';
import { GraficoVolumetriaTipoCheckup } from 'src/app/_models/GraficoVolumetriaTipoCheckup';

@Component({
    selector: 'app-principal-grafico',
    templateUrl: 'principal-grafico.component.html',
    styleUrls: ['./principal-grafico.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class PrincipalGraficoComponent {

    /*Propriedades*/
    @ViewChild(GraficoMelhorAvaliacaoComponent) graficoMelhorAvaliacao;
    @ViewChild(GraficoVolumetriaAvaliacaoComponent) graficoVolumetriaAvaliacao;
    @ViewChild(GraficoMetaRealizadoPrevistoComponent) graficoMetaRealizadoPrevisto;
    @ViewChild(GraficoVolumetriaTipoCheckupComponent) graficoVolumetriaTipoCheckup;
    @ViewChild(MenuGraficoComponent) menuGrafico;
    loading = false;
    dadosGraficoMelhorAvaliacao: GraficoMelhorAvaliacao;
    dadosGraficoVolumetriaAvaliacao: GraficoVolumetriaAvaliacao;
    dadosGraficoMetaRealizadoPrevisto: GraficoMetaRealizadoPrevisto;
    dadosGraficoVolumetriaTipoCheckup: GraficoVolumetriaTipoCheckup;

    /*Construtores*/
    constructor(private toast: ToastrService,
        private apiGraficoService: GraficoService,
        private alertService: AlertService,
        private datepipe: DatePipe) {
    };

    /*Métodos públicos*/
    exibirGrafico() {
        if (this.filtroGraficoEstaValido()) {
            this.desabilitarGraficos();

            switch (this.menuGrafico.cmbModeloValor) {
                case ModeloGrafico.MelhoresAvaliacoes.toString():
                    this.carregarGraficoMelhorAvaliacao();
                    break;
                case ModeloGrafico.VolumetriaAvaliacoes.toString():
                    this.carregarGraficoVolumetriaAvaliacao();
                    break;
                case ModeloGrafico.MetaRealizadoPrevisto.toString():
                    this.carregarGraficoMetaRealizadoPrevisto();
                    break;
                case ModeloGrafico.VolumetriaTipoCheckup.toString():
                    this.carregarGraficoVolumetriaTipoCheckup();
                    break;
            }
        }
    }

    limparCampos(){
        this.menuGrafico.limparTodosCampos();
    }

    /*Métodos privados*/
    private desabilitarGraficos(): void{
        this.graficoVolumetriaAvaliacao.visible = false;
        this.graficoMelhorAvaliacao.visible = false;
        this.graficoMetaRealizadoPrevisto.visible = false;
        this.graficoVolumetriaTipoCheckup.visible = false;
    }

    private exibirMensagemAlerta(msgAlerta: string) {
        this.toast.warning(msgAlerta, "Não foi possível carregar o gráfico!");
    }

    private fecharMensagens() {
        this.toast.clear();
    }

    private filtroGraficoEstaValido(): boolean {
        this.fecharMensagens();

        if (!this.modeloEstaSelecionado()) {
            this.exibirMensagemAlerta('O modelo de gráfico deve ser selecionado!');
            return false;
        }

        if (this.modeloGraficoSelecionadoPossuiPeriodo()) {
            if (!this.campofiltroDataInicioEstaValido()) {
                this.exibirMensagemAlerta('A data inicial do filtro deve ser informada!');
                return false;
            }
            if (!this.campofiltroDataFimEstaValido()) {
                this.exibirMensagemAlerta('A data final do filtro deve ser informada!');
                return false;
            }
            if (!this.camposFiltroDataEstaoOrdenados()) {
                this.exibirMensagemAlerta('A data final deve ser maior ou igual a data inicial!');
                return false;
            }
        }
        else if (this.modeloGraficoSelecionadoPossuiMesAno()) {
            if (!this.campoAnoEstaValido()){
                this.exibirMensagemAlerta('O Ano informado está incorreto!');
                return false;
            }
        }

        return true;
    }

    private modeloGraficoSelecionadoPossuiPeriodo(): boolean {
        if (this.menuGrafico.cmbModeloValor == ModeloGrafico.MelhoresAvaliacoes.toString()) {
            return true;
        }
        else if (this.menuGrafico.cmbModeloValor == ModeloGrafico.VolumetriaAvaliacoes.toString()) {
            return false;
        }

        return false;
    }

    private modeloGraficoSelecionadoPossuiMesAno(): boolean {
        if (this.menuGrafico.cmbModeloValor == ModeloGrafico.MetaRealizadoPrevisto.toString()) {
            return true;
        }

        return false;
    }

    private campoAnoEstaValido(): boolean {
        let ano = Number(this.menuGrafico.txtAnoValor);
        if (!isNaN(ano) && ano > 1500 && ano <= 9999) {
            return true;
        }

        return false;
    }

    private campofiltroDataInicioEstaValido(): boolean {
        if (this.menuGrafico.pickerDataInicialValor != null) {
            return true;
        }

        return false;
    }

    private campofiltroDataFimEstaValido(): boolean {
        if (this.menuGrafico.pickerDataFinalValor != null) {
            return true;
        }

        return false;
    }

    private camposFiltroDataEstaoOrdenados(): boolean {
        if (this.menuGrafico.pickerDataInicialValor.getTime() <= this.menuGrafico.pickerDataFinalValor.getTime()) {
            return true;
        }

        return false;
    }

    private modeloEstaSelecionado(): boolean {
        var modeloSelecionado = this.menuGrafico.cmbModeloValor;
        if (modeloSelecionado == '0' || modeloSelecionado == null) {
            return false;
        }

        return true;
    }

    private carregarGraficoMelhorAvaliacao() {        
        this.loading = true;
        var dtInicial = this.converterParaStringFormatada(this.menuGrafico.pickerDataInicialValor);
        var dtFinal = this.converterParaStringFormatada(this.menuGrafico.pickerDataFinalValor);
        var idRegional = this.menuGrafico.cmbRegionalValor;
        var idLoja = this.menuGrafico.cmbLojaValor;
        var idDiretor = this.menuGrafico.cmbDiretorValor;

        this.apiGraficoService.getMelhorAvaliacao(dtInicial, dtFinal, idRegional, idLoja, idDiretor)
            .subscribe(
                rtmc => this.dadosGraficoMelhorAvaliacao = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {                    
                    this.graficoMelhorAvaliacao.definirDadosGrafico(this.dadosGraficoMelhorAvaliacao)
                    this.loading = false;
                    this.graficoMelhorAvaliacao.visible = true;
                });
    }

    private carregarGraficoVolumetriaAvaliacao() {        
        this.loading = true;
        var dtInicial = this.converterParaStringFormatada(this.menuGrafico.pickerDataInicialValor);
        var dtFinal = this.converterParaStringFormatada(this.menuGrafico.pickerDataFinalValor);
        var idRegional = this.menuGrafico.cmbRegionalValor;
        var idLoja = this.menuGrafico.cmbLojaValor;
        var idDiretor = this.menuGrafico.cmbDiretorValor;

        this.apiGraficoService.getVolumetriaAvaliacao(dtInicial, dtFinal, idRegional, idLoja, idDiretor)
            .subscribe(
                rtmc => this.dadosGraficoVolumetriaAvaliacao = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {
                    this.graficoVolumetriaAvaliacao.definirDadosGrafico(this.dadosGraficoVolumetriaAvaliacao);
                    this.loading = false;
                    this.graficoVolumetriaAvaliacao.visible = true;
                });
    }

    private carregarGraficoMetaRealizadoPrevisto() {
        this.loading = true;
        var idMes = this.menuGrafico.cmbMesValor;
        var ano = this.menuGrafico.txtAnoValor;
        var idRegional = this.menuGrafico.cmbRegionalValor;
        var idLoja = this.menuGrafico.cmbLojaValor;
        var idDiretor = this.menuGrafico.cmbDiretorValor;

        this.apiGraficoService.getMetaRealizadoPrevisto(idMes, ano, idRegional, idLoja, idDiretor)
            .subscribe(
                rtmc => this.dadosGraficoMetaRealizadoPrevisto = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {
                    this.graficoMetaRealizadoPrevisto.definirDadosGrafico(this.dadosGraficoMetaRealizadoPrevisto);
                    this.loading = false;
                    this.graficoMetaRealizadoPrevisto.visible = true;
                });
    }

    private carregarGraficoVolumetriaTipoCheckup() {
        this.loading = true;
        var idMes = this.menuGrafico.cmbMesValor;
        var ano = this.menuGrafico.txtAnoValor;
        var idRegional = this.menuGrafico.cmbRegionalValor;
        var idLoja = this.menuGrafico.cmbLojaValor;
        var idDiretor = this.menuGrafico.cmbDiretorValor;

        this.apiGraficoService.getVolumetriaTipoCheckup(idMes, ano, idRegional, idLoja, idDiretor)
            .subscribe(
                rtmc => this.dadosGraficoVolumetriaTipoCheckup = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {
                    this.graficoVolumetriaTipoCheckup.definirDadosGrafico(this.dadosGraficoVolumetriaTipoCheckup);
                    this.loading = false;
                    this.graficoVolumetriaTipoCheckup.visible = true;
                });
    }

    private converterParaStringFormatada(data: Date): string {
        return this.datepipe.transform(data, 'yyyy-MM-dd');
    }
}