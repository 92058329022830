import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';

@Component({
  selector: 'app-lista-detalhe-registro',
  templateUrl: './lista-detalhe-registro.component.html'
})
export class ListaDetalheRegistroComponent implements OnInit {
  
  public tipoMenuValue: number = TipoMenu.Checkup;

  constructor() { }

  ngOnInit() {
  }

}