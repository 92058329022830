import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html'
})
export class HomePageComponent implements OnInit {

  public tipoMenuValue: number = TipoMenu.Home;

  constructor() { }

  ngOnInit() {
  }

}