﻿
import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { ITooltipRenderEventArgs, ChartComponent } from '@syncfusion/ej2-angular-charts';
import { GraficoVolumetriaAvaliacao } from 'src/app/_models/GraficoVolumetriaAvaliacao';
import { PopupMessagesComponent } from 'src/app/_components/popup-messages/popup-messages.component';
import { Message} from 'src/app/_models';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-volumetria-avaliacao',
    templateUrl: 'volumetria-avaliacao.component.html',
    styleUrls: ['./volumetria-avaliacao.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class GraficoVolumetriaAvaliacaoComponent {

    /*Propriedades*/
    visible: boolean = false;
    @ViewChild('chartVolumetriaAvaliacao')
    public chartVolumetriaAvaliacao: ChartComponent;
    public chartData: Object[];
    public tooltip: Object;
    public marker: Object;
    public primaryXAxis: Object;
    public primaryYAxis: Object;
    public title: string;
    loading = false;

    /*Construtores*/
    constructor(private dialog?: MatDialog) {
    };

    /*Métodos públicos*/
    public tooltipRender(args: ITooltipRenderEventArgs): void {
        if (args.series.yName == 'media') {
            var xNomeTooltip = args.point.x;
            var textoTooltip = args.text;
            args.text = textoTooltip.replace(xNomeTooltip.toLocaleString(), 'Média')
        }
    };

    public definirDadosGrafico(dadosGrafico: GraficoVolumetriaAvaliacao): void {
        if(dadosGrafico != null && dadosGrafico.volumetriaCorretores != null){
            this.tratarDadosGrafico(dadosGrafico);
        }
        else{
            this.chartData = [];
        }
    }

    public exportarImg(): void {
        this.chartVolumetriaAvaliacao.exportModule.export('PNG', 'GraficoVolumetriaAvaliacao', null, [this.chartVolumetriaAvaliacao]);
    }

    public exibirAlertaInfo(): void {
        var titulo = "Informações sobre o gráfico Volumetria de avaliações";
        var subTitulo = "";
        var msg = "Esse gráfico representa  a quantidade de avaliações por corretor de acordo com os filtros (Regional e Período) e a média de checkups por corretor.";
        var messageModel = new Message(titulo, subTitulo, msg, "info");

        this.dialog.open(PopupMessagesComponent, { data: messageModel });
    }

    /*Métodos privados*/
    private tratarDadosGrafico(dadosGrafico: GraficoVolumetriaAvaliacao): void {
        this.chartData = [];
        dadosGrafico.volumetriaCorretores.forEach(d => {
            this.chartData.push({ media: dadosGrafico.media, corretor: d.nomeCorretor, quantidade: d.quantCheckup })
        });
    }

    /*Eventos*/
    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category',
            title: 'Corretores',
            labelIntersectAction: 'Rotate45',
            labelStyle: { size: '9px', fontWeight : 'bold' }
        };
        this.primaryYAxis = { title: 'Quantidade', labelFormat: '{value} checkups'};
        this.tooltip = { enable: true };
        this.marker = {  visible: false, height: 10, width: 80 };
        this.title = 'Volumetria das quantidades de avaliações por corretor';
    }
}
