﻿import { Component, OnInit } from '@angular/core';
import { User } from '../../../_models/user';

@Component({
    selector: 'app-home-checkup',
    templateUrl: 'home-checkup.component.html'
})

export class HomeCheckupComponent implements OnInit {
    currentUser: User;
    currentDate: string;

    dayname = new Array("Domingo", "Segunda-feira", "Terça-feira", "Quarta-Feira", "Quinta-feira", "Sexta-feira", "Sábado");        
    monName = new Array("janeiro", "fevereiro", "março", "abril", "Maio", "junho", "agosto", "outubro", "novembro", "dezembro");

    constructor() {
        let dateNow = new Date();
        this.currentDate = `${this.dayname[dateNow.getDay()]}, ${dateNow.getDate ()} do ${dateNow.getMonth()+1} de ${dateNow.getFullYear ()}.`;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
    }
}
