﻿
import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { GraficoVolumetriaTipoCheckup } from 'src/app/_models/GraficoVolumetriaTipoCheckup';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import { PopupMessagesComponent } from 'src/app/_components/popup-messages/popup-messages.component';
import { Message} from 'src/app/_models';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-volumetria-tipo-checkup',
    templateUrl: 'volumetria-tipo-checkup.component.html',
    styleUrls: ['./volumetria-tipo-checkup.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class GraficoVolumetriaTipoCheckupComponent {

    /*Propriedades*/
    visible: boolean = false;
    @ViewChild('chartVolumetriaTipoCheckup')
    public chartVolumetriaTipoCheckup: ChartComponent;
    public chartData: Object[];
    public tooltip: Object;
    public marker: Object;
    public primaryXAxis: Object;
    public primaryYAxis: Object;
    public title: string;
    loading = false;

    /*Construtores*/
    constructor(private dialog?: MatDialog) {
    };

    /*Métodos públicos*/
    public definirDadosGrafico(dadosGrafico: GraficoVolumetriaTipoCheckup[]): void {   
        if(dadosGrafico != null){
            this.chartData = dadosGrafico;
        }
        else{
            this.chartData = [];
        }
    }

    public exportarImg(): void {
        this.chartVolumetriaTipoCheckup.exportModule.export('PNG', 'GraficoVolumetriaTipoCheckup', null, [this.chartVolumetriaTipoCheckup]);
    }

    public exibirAlertaInfo(): void {
        var titulo = "Informações sobre o gráfico Volumetria de tipo de checkups";
        var subTitulo = "";
        var msg = "Esse gráfico representa a volumetria de checkups realizados por período e dividido entre os tipos de checkups disponíveis.";
        var messageModel = new Message(titulo, subTitulo, msg, "info");

        this.dialog.open(PopupMessagesComponent, { data: messageModel });
    }

    /*Métodos privados*/


    /*Eventos*/
    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category',
            title: 'Gerentes',
            labelIntersectAction: 'Rotate45',
            labelStyle: { size: '9px', fontWeight : 'bold' }
        };
        this.primaryYAxis = { title: 'Quantidade', labelFormat: '{value} checkups'};
        this.tooltip = { enable: true };
        this.marker = {  visible: false, height: 10, width: 15 };
        this.title = 'Volumetria de tipo de checkups';
    }
}
