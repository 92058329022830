
export enum TipoFormulario {
  VendasPresencial = 1,
  VendasPresencialDescription = 'CHECK-UP DE VENDAS PRESENCIAL',
  VendasPresencialRouterLink = '/checkup/vendasPresencial',

  VendasTelefone = 2,
  VendasTelefoneDescription = 'CHECK-UP DE VENDAS TELEFONE',
  VendasTelefoneRouterLink = '/checkup/vendasTelefone',

  VendasChat = 3,
  VendasChatDescription = 'CHECK-UP DE VENDAS VIRTUAL CHAT',
  VendasChatRouterLink = '/checkup/vendasChat'
}