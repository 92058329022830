﻿
import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { GraficoMetaRealizadoPrevisto } from 'src/app/_models/GraficoMetaRealizadoPrevisto';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import { PopupMessagesComponent } from 'src/app/_components/popup-messages/popup-messages.component';
import { Message} from 'src/app/_models';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-meta-realizado-previsto',
    templateUrl: 'meta-realizado-previsto.component.html',
    styleUrls: ['./meta-realizado-previsto.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class GraficoMetaRealizadoPrevistoComponent {

    /*Propriedades*/
    visible: boolean = false;
    @ViewChild('chartMetaRealizadoPrevisto')
    public chartMetaRealizadoPrevisto: ChartComponent;
    public chartData: Object[];
    public tooltip: Object;
    public marker: Object;
    public primaryXAxis: Object;
    public primaryYAxis: Object;
    public title: string;
    loading = false;

    /*Construtores*/
    constructor(private dialog?: MatDialog) {
    };

    /*Métodos públicos*/
    public definirDadosGrafico(dadosGrafico: GraficoMetaRealizadoPrevisto[]): void {   
        if(dadosGrafico != null){
            this.chartData = dadosGrafico;
        }
        else{
            this.chartData = [];
        }
    }

    public exportarImg(): void {
        this.chartMetaRealizadoPrevisto.exportModule.export('PNG', 'GraficoMetaRealizadoPrevisto', null, [this.chartMetaRealizadoPrevisto]);
    }

    public exibirAlertaInfo(): void {
        var titulo = "Informações sobre o gráfico Meta x Realizado x Previsto";
        var subTitulo = "";
        var msg = "Previsto: 1 formulário por corretor/mês por loja Meta: Caso de lojas com até 15 corretores: 1 formulário por corretor/mês Caso de lojas com 15 ou mais corretores: 15 Realizado: quantidade de formulários registrados no período.";
        var messageModel = new Message(titulo, subTitulo, msg, "info");

        this.dialog.open(PopupMessagesComponent, { data: messageModel });
    }

    /*Métodos privados*/


    /*Eventos*/
    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category', 
            title: 'Gerentes', 
            labelIntersectAction: 'Rotate45',
            labelStyle: { size: '9px', fontWeight : 'bold' }
        };
        this.primaryYAxis = { title: 'Quantidade', labelFormat: '{value} checkups'};
        this.tooltip = { enable: true };
        this.marker = {  visible: false, height: 10, width: 15 };
        this.title = 'Meta x Realizado x Previsto';
    }
}
