import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { PopSummary } from '../_models/PopSummary';
import { PopConteudo } from '../_models/PopConteudo';
import { PopSubtituloInsert } from '../_models/PopSubtituloInsert';
import { PopTituloPrincipalInsert } from '../_models/PopTituloPrincipalInsert';
import { PopConteudoUpdate } from '../_models/PopConteudoUpdate';
import { PopTituloUpdate } from '../_models/PopTituloUpdate';
import { DataService } from './core/data.service';
import { CommandResult } from '../_models/CommandResult';

@Injectable({
    providedIn: 'root'
})

export class MovService extends DataService {
    context = 'Mov';

    getPopSummary(): Observable<any> {
        return this.get<PopSummary[]>('GetPopSummary');
        //return this.http.get<PopSummary[]>(`${environment.apiUrl}/Mov/GetPopSummary`);
    }

    getPopConteudoById(id: number): Observable<any> {
        return this.get<PopConteudo>('GetPopConteudoById', id);
        //return this.http.get<PopConteudo>(`${environment.apiUrl}/Mov/GetPopConteudoById/`+id);
    }

    putPopConteudo(popConteudo: PopConteudoUpdate): Observable<any> {
        return this.put<CommandResult>('PutPopConteudo', popConteudo);
        //return this.http.put<PopConteudoUpdate>(`${environment.apiUrl}/Mov/PutPopConteudo/`, popConteudo);
    }

    postSubtitulo(popSubtitulo: PopSubtituloInsert): Observable<any> {
        return this.post<CommandResult>('PostSubtitulo', popSubtitulo);
        //return this.http.post<PopSubtituloInsert>(`${environment.apiUrl}/Mov/PostSubtitulo/`, popSubtitulo);
    }

    postTituloPrincipal(popTituloPrincipal: PopTituloPrincipalInsert): Observable<any> {
        return this.post<CommandResult>('PostTituloPrincipal', popTituloPrincipal);
        //return this.http.post<PopTituloPrincipalInsert>(`${environment.apiUrl}/Mov/PostTituloPrincipal/`, popTituloPrincipal);
    }

    putTitulo(popTitulo: PopTituloUpdate): Observable<any> {
        return this.put<CommandResult>('PutPopTitulo', popTitulo);
        //return this.http.put<PopTituloUpdate>(`${environment.apiUrl}/Mov/PutPopTitulo/`, popTitulo);
    }

    deleteTitulo(id: number): Observable<any> {
        return this.delete<CommandResult>('DeletePopTitulo', id);
        //return this.http.delete<PopTituloDelete>(`${environment.apiUrl}/Mov/DeletePopTitulo/`, popTitulo);
    }

}
