import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from '../_services/alert.service';
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})

export class DocumentoPreEditadoService {

    constructor(private http: HttpClient,
        private alertService: AlertService) { }

    CriarDocumentoPreEditado(formDataUpload: FormData): Observable<any> {        
        let apiURL = `${environment.apiUrl}/api/ProtocoloNotaFiscal`
        let headers = new HttpHeaders({
            'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT'
        });

        let options = {
                headers: headers
        };
        
        return this.http.post<any>(apiURL, formDataUpload, options);
    }

    AtualizarDocumentoPreEditado(formDataUpload: FormData): Observable<any> {        
        let apiURL = `${environment.apiUrl}/api/ProtocoloNotaFiscal/update`
        let headers = new HttpHeaders({
            'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT'
        });

        let options = {
            headers: headers
        };
        
        return this.http.post<any>(apiURL, formDataUpload, options);
    }
    
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead

            this.alertService.error(`Ocorreu um problema ao verificar aempresa MRV. Detalhes: \n ${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
