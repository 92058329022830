﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { TextEditorMovComponent } from '../text-editor-mov/text-editor-mov.component';

@Component({
    selector: 'home-mov',
    templateUrl: 'home-mov.component.html',
    styleUrls: ['home-mov.component.css']
})

export class HomeMovComponent implements OnInit {

    /* Propriedades */

    /* Construtor */
    constructor() {
    }

    /* Métodos privados */

    /* Métodos públicos */

    /* Eventos */
    ngOnInit() {
    }
}
