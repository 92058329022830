import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';

@Component({
  selector: 'app-pop-page-mov',
  templateUrl: './pop-page-mov.component.html'
})
export class PopPageMovComponent implements OnInit {

  public tipoMenuValue: number = TipoMenu.Mov;

  constructor() { }

  ngOnInit() {
  }
}