﻿
import { Component, ViewEncapsulation, ViewChild  } from '@angular/core';
import { ITooltipRenderEventArgs, ChartComponent } from '@syncfusion/ej2-angular-charts';
import { GraficoMelhorAvaliacao } from 'src/app/_models/GraficoMelhorAvaliacao';
import { MatDialog } from '@angular/material';
import { PopupMessagesComponent } from 'src/app/_components/popup-messages/popup-messages.component';
import { Message} from 'src/app/_models';

@Component({
    selector: 'app-melhor-avaliacao',
    templateUrl: 'melhor-avaliacao.component.html',
    styleUrls: ['./melhor-avaliacao.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class GraficoMelhorAvaliacaoComponent {

    /*Propriedades*/
    
    visible: boolean = false;
    @ViewChild('chartMelhorAvaliacao')
    public chartMelhorAvaliacao: ChartComponent;
    public chartData: Object[];
    public tooltip: Object;
    public marker: Object;
    public primaryXAxis: Object;
    public primaryYAxis: Object;
    public title: string;
    loading = false;

    /*Construtores*/
    constructor(private dialog?: MatDialog) {
    };

    /*Métodos públicos*/
    public definirDadosGrafico(dadosGrafico: GraficoMelhorAvaliacao): void {
        if(dadosGrafico != null && dadosGrafico.notasCorretores != null){
            this.tratarDadosGrafico(dadosGrafico);
        }
        else{
            this.chartData = [];
        }
    }

    public exportarImg(): void {
        this.chartMelhorAvaliacao.exportModule.export('PNG', 'GraficoMelhorAvaliacao', null, [this.chartMelhorAvaliacao]);
    }

    public exibirAlertaInfo(): void {
        var titulo = "Informações sobre o gráfico Média de avaliações";
        var subTitulo = "";
        var msg = "Esse gráfico representa a média das avaliações de cada corretor e a média geral de acordo com os filtros (Regional e Período). " +
                  "Caso o corretor tenha mais de um checkup registrado no período filtrado, as notas dos formulários serão somadas e a média será demonstrada no gráfico.";
        var messageModel = new Message(titulo, subTitulo, msg, "info");
        this.dialog.open(PopupMessagesComponent, { data: messageModel });
    }

    /*Métodos privados*/
    private tratarDadosGrafico(dadosGrafico: GraficoMelhorAvaliacao): void {
        this.chartData = [];
        dadosGrafico.notasCorretores.forEach(d => {
            this.chartData.push({ media: dadosGrafico.media, corretor: d.nomeCorretor, nota: d.nota })
        });
    }

    /*Eventos*/
    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category', 
            title: 'Corretores', 
            labelIntersectAction: 'Rotate45',
            labelStyle: { size: '9px', fontWeight : 'bold' }
        };
        this.primaryYAxis = {
            title: 'pontuações', 
            labelFormat: '{value} pts', 
            minimum: 0, 
            maximum: 10, 
            interval: 2 };
        this.tooltip = { enable: true };
        this.marker = {  visible: false, height: 10, width: 80 };
        this.title = 'Média das avaliações por corretor';
    }

    public tooltipRender(args: ITooltipRenderEventArgs): void {
        if (args.series.yName == 'media') {
            var xNomeTooltip = args.point.x;
            var textoTooltip = args.text;
            args.text = textoTooltip.replace(xNomeTooltip.toLocaleString(), 'Média')
        }
    };
}