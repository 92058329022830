﻿import { Component, ViewEncapsulation } from '@angular/core';
import { Regional } from 'src/app/_models/Regional';
import { AlertService, RegionalService, LojaService, FuncionarioService } from '../../../../_services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GraficoService } from 'src/app/_services/grafico.service';
import { FormatoGrafico } from 'src/app/_models/FormatoGrafico';
import { Loja, Funcionario } from 'src/app/_models';
import { ModeloGrafico } from 'src/app/_enums/ModeloGrafico';
import { Mes } from 'src/app/_models/Mes';

@Component({
    selector: 'app-menu-grafico',
    templateUrl: 'menu-grafico.component.html',
    styleUrls: ['./menu-grafico.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MenuGraficoComponent {

    /*Propriedades*/
    regionais = new Array<Regional>();
    lojas = new Array<Loja>();
    modelos = new Array<FormatoGrafico>();
    diretores = new Array<Funcionario>();
    meses = new Array<Mes>();
    loading = false;
    cmbRegionalValor: string = '0';
    cmbLojaValor: string = '0';
    cmbModeloValor: string = '0';
    cmbDiretorValor: string = '0';
    cmbMesValor: string = '0';
    txtAnoValor: string = '0000';
    pickerDataInicialValor: Date;
    pickerDataFinalValor: Date;    
    cmbLojaDesabilitado: boolean = true;
    cmbRegionalDesabilitado: boolean = true;    
    cmbDiretorDesabilitado: boolean = true;  
    cmbMesDesabilitado: boolean = true; 
    txtAnoDesabilitado: boolean = true; 
    dvLojaHabilitado: boolean = false;
    dvRegionalHabilitado: boolean = false;
    dvPickerDataInicialHabilitado: boolean = false;
    dvPickerDataFinalHabilitado: boolean = false;
    dvDiretorHabilitado: boolean = false;
    dvMesHabilitado: boolean = false;
    dvAnoHabilitado: boolean = false;
    frmMenuGrafico: FormGroup;
    funcionarioLogadoPodeVisualizarDiretor: boolean = false;
    get f() { return this.frmMenuGrafico.controls; }

    /*Construtores*/
    constructor(
        private alertService: AlertService,
        private apiRegionalService: RegionalService,
        private apiLojaService: LojaService,
        private apiGraficoService: GraficoService,
        private apiFuncionarioService: FuncionarioService,
        private formBuilder: FormBuilder
    ) { }

    /*Métodos privados*/

    private carregarComboRegional(): void {
        this.loading = true;
        this.apiRegionalService.getByPerfilLogin()
            .subscribe(
                rtmc => this.regionais = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {
                    this.loading = false;
                });
    }

    private carregarComboModelo(): void {
        this.loading = true;
        this.modelos = this.apiGraficoService.getModelos();
        this.loading = false;
    }

    private carregarComboLoja(): void {
        this.loading = true;
        this.apiLojaService.getByPerfilLoginRegional(this.cmbRegionalValor)
            .subscribe(
                rtmc => this.lojas = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {
                    this.loading = false;
                });
    }

    private carregarComboDiretor(): void {
        if (this.funcionarioLogadoPodeVisualizarDiretor) {
            this.loading = true;
            this.apiFuncionarioService.getDiretores()
                .subscribe(
                    rtmc => this.diretores = rtmc.data,
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    }
                    , () => {
                        this.loading = false;
                    });
        }
    }    

    private carregarComboMes(): void {
        this.loading = true;
        this.apiGraficoService.getMeses()
            .subscribe(
                rtmc => this.meses = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {
                    this.definirMesAnoPadraoFiltro();
                    this.loading = false;
                });
    }  

    private habilitarComboLoja(): void {
        this.cmbLojaDesabilitado = false;
    }

    private habilitarComboRegional(): void {
        this.cmbRegionalDesabilitado = false;
    }

    private habilitarComboDiretor(): void {
        this.cmbDiretorDesabilitado = false;
    }

    private habilitarComboMes(): void {
        this.cmbMesDesabilitado = false;
    }

    private habilitarTxtAno(): void {
        this.txtAnoDesabilitado = false;
    }

    private habilitarDvPickerPeriodo(): void {
        this.dvPickerDataInicialHabilitado = true;
        this.dvPickerDataFinalHabilitado = true;
    }

    private habilitarDvLoja(): void {
        this.dvLojaHabilitado = true;
    }

    private habilitarDvRegional(): void {
        this.dvRegionalHabilitado = true;
    }

    private habilitarDvDiretor(): void {
        this.dvDiretorHabilitado = true;
    }

    private habilitarDvMesAno(): void {
        this.dvMesHabilitado = true;
        this.dvAnoHabilitado = true;
    }

    private desabilitarComboLoja(): void {
        this.cmbLojaDesabilitado = true;
    }

    private desabilitarComboDiretor(): void {
        this.cmbDiretorDesabilitado = true;
    }

    private desabilitarComboRegional(): void {
        this.cmbRegionalDesabilitado = true;
    }

    private desabilitarComboMes(): void {
        this.cmbMesDesabilitado = true;
    }

    private desabilitarTxtAno(): void {
        this.txtAnoDesabilitado = true;
    }

    private desabilitarDvPickerPeriodo(): void {
        this.dvPickerDataInicialHabilitado = false;
        this.dvPickerDataFinalHabilitado = false;
    }

    private desabilitarDvLoja(): void {
        this.dvLojaHabilitado = false;
    }

    private desabilitarDvRegional(): void {
        this.dvRegionalHabilitado = false;
    }

    private desabilitarDvDiretor(): void {
        this.dvDiretorHabilitado = false;
    }

    private desabilitarDvMesAno(): void {
        this.dvMesHabilitado = false;
        this.dvAnoHabilitado = false;
    }

    private tratarVisibilidadeLoja(): void{
        if(this.cmbRegionalValor == '0' ){
            this.desabilitarComboLoja();
            this.desabilitarDvLoja();
        }
        else{
            this.habilitarComboLoja();
            this.habilitarDvLoja();
        }
    }

    private tratarVisibilidadeRegional(): void{
        if(this.cmbModeloValor == '0' ){
            this.desabilitarComboRegional();
            this.desabilitarDvRegional();
        }
        else{
            this.habilitarComboRegional();
            this.habilitarDvRegional();
        }
    }

    private tratarVisibilidadePeriodo(): void{
        if(this.cmbModeloValor == '0' ){
            this.desabilitarDvPickerPeriodo();
        }
        else{            
            this.habilitarDvPickerPeriodo();
        }
    }

    private tratarVisibilidadeDiretor(){
        if(this.funcionarioLogadoPodeVisualizarDiretor){
            this.habilitarComboDiretor();
            this.habilitarDvDiretor();
        }
        else{
            this.desabilitarComboDiretor();
            this.desabilitarDvDiretor();
        }
    }

    private tratarVisibilidadeMesAno(): void{
        if(this.cmbModeloValor == '0' ){
            this.desabilitarComboMes();
            this.desabilitarTxtAno();
            this.desabilitarDvMesAno();
        }
        else{
            this.habilitarComboMes();
            this.habilitarTxtAno();
            this.habilitarDvMesAno();
        }
    }

    private tratarVisibilidadeCamposTelaPadrao(): void{     
        this.tratarVisibilidadeLoja();
        this.tratarVisibilidadeRegional();
        this.tratarVisibilidadePeriodo();
        this.tratarVisibilidadeMesAno();
    }

    private tratarVisibilidadeCarregamentoDiretor(): void {
        this.loading = true;
        this.apiFuncionarioService.getFuncionarioLogadoPodeVisualizarDiretor()
            .subscribe(
                rtmc => this.funcionarioLogadoPodeVisualizarDiretor = rtmc.data,
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
                , () => {           
                    this.loading = false;
                    this.tratarVisibilidadeDiretor();
                    this.carregarComboDiretor();
                });
    }

    private carregarFormulario() {
        this.frmMenuGrafico = this.formBuilder.group({
            cmbDiretor: this.cmbDiretorValor,
            cmbRegional: this.cmbRegionalValor,
            cmbLoja: this.cmbLojaValor,
            cmbModelo: this.cmbModeloValor,
            cmbMes: this.cmbMesValor,
            pickerDataInicial: this.pickerDataInicialValor,
            pickerDataFinal: this.pickerDataFinalValor,
            txtAno: this.txtAnoValor
        });
    }

    private limparComboLoja(): void {
        if(this.cmbRegionalValor == '0' ){
            this.lojas = new Array<Loja>();
            this.frmMenuGrafico.get('cmbLoja').reset();
        }
    }

    private definirDataInicialFiltro(): void {
        var agora = new Date();
        this.pickerDataInicialValor = new Date(agora.setMonth(agora.getMonth() - 1));
    }

    private definirDataFinalFiltro(): void {
        var agora = new Date();
        this.pickerDataFinalValor = agora;
    }

    private definirMesAnoPadraoFiltro(): void {
        var agora = new Date();
        var mes = (agora.getMonth() + 1).toString();
        var ano = agora.getFullYear();
        this.frmMenuGrafico.get('cmbMes').setValue(mes);
        this.frmMenuGrafico.get('txtAno').setValue(ano);
    }

    private definirSelecaoPadraoCombo(nomeCombo: string): void {
        this.frmMenuGrafico.get(nomeCombo).setValue('0');
    }

    private tratarCamposPorModeloSelecionado(): void {
        switch (this.cmbModeloValor) {
            case ModeloGrafico.MelhoresAvaliacoes.toString():
                this.tratarCamposFiltroPeriodo();
                break;
            case ModeloGrafico.VolumetriaAvaliacoes.toString():
                this.tratarCamposFiltroPeriodo();
                break;
            case ModeloGrafico.MetaRealizadoPrevisto.toString():
                this.tratarCamposFiltroMesAno();
                break;
            case ModeloGrafico.VolumetriaTipoCheckup.toString():
                this.tratarCamposFiltroMesAno();
                break;
        }
    }

    private tratarCamposFiltroPeriodo(): void {
        this.tratarVisibilidadeRegional();
        this.carregarComboRegional();
        this.tratarVisibilidadePeriodo();
        this.desabilitarDvMesAno();
        this.desabilitarComboMes();
    }

    private tratarCamposFiltroMesAno(): void {
        this.tratarVisibilidadeRegional();
        this.carregarComboRegional();
        this.desabilitarDvPickerPeriodo();
        this.tratarVisibilidadeMesAno();
        this.carregarComboMes();
    }    

    /*Métodos públicos*/
    tratarEventoComboRegional(): void {        
        this.carregarComboLoja();
        this.limparComboLoja();
        this.tratarVisibilidadeLoja();
        this.definirSelecaoPadraoCombo('cmbLoja');   
    }

    tratarEventoComboModelo(): void {        
        if(this.cmbModeloValor == '0' ){
            this.definirSelecaoPadraoCombo('cmbModelo');
            this.definirSelecaoPadraoCombo('cmbRegional'); 
            this.definirSelecaoPadraoCombo('cmbLoja');
            this.definirMesAnoPadraoFiltro();
            this.tratarVisibilidadeCamposTelaPadrao();
        }
        else{
            this.tratarCamposPorModeloSelecionado();            
        }
    }

    limparTodosCampos(): void{
        this.definirSelecaoPadraoCombo('cmbModelo');
        this.definirSelecaoPadraoCombo('cmbRegional'); 
        this.definirSelecaoPadraoCombo('cmbLoja');
        this.definirMesAnoPadraoFiltro();
        this.definirDataInicialFiltro();
        this.definirDataFinalFiltro();
        this.tratarVisibilidadeCamposTelaPadrao();
    }

    /*Eventos*/
    ngOnInit() {    
        this.tratarVisibilidadeCarregamentoDiretor();     
        this.carregarFormulario();        
        this.carregarComboModelo();
        this.tratarVisibilidadeCamposTelaPadrao();        
        this.definirDataInicialFiltro();
        this.definirDataFinalFiltro();
    }

    onSubmit() {
    }
}