import { Grafico } from '../_models/Grafico';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'
import { FormatoGrafico } from '../_models/FormatoGrafico';
import { GraficoMelhorAvaliacao } from '../_models/GraficoMelhorAvaliacao';
import { GraficoVolumetriaAvaliacao } from '../_models/GraficoVolumetriaAvaliacao';
import { ModeloGrafico } from '../_enums/ModeloGrafico';
import { Mes } from '../_models/Mes';
import { GraficoMetaRealizadoPrevisto } from '../_models/GraficoMetaRealizadoPrevisto';
import { GraficoVolumetriaTipoCheckup } from '../_models/GraficoVolumetriaTipoCheckup';

@Injectable({
    providedIn: 'root'
})

export class GraficoService {

    getMelhorAvaliacao(dataInicial: string, dataFinal: string, idRegional: string, idLoja: string, idDiretor: string): Observable<any>  {
        return this.http.get<GraficoMelhorAvaliacao>(`${environment.apiUrl}/Grafico/GetMelhorAvaliacao/${dataInicial}/${dataFinal}/${idRegional}/${idLoja}/${idDiretor}`);
    }

    getModelos(): Array<FormatoGrafico> {
        var modelos: Array<FormatoGrafico> = [            
            new FormatoGrafico(ModeloGrafico.MelhoresAvaliacoes.toString(), 'Média de avaliações '),
            new FormatoGrafico(ModeloGrafico.VolumetriaAvaliacoes.toString(), 'Volumetria de avaliações'),
            new FormatoGrafico(ModeloGrafico.MetaRealizadoPrevisto.toString(), 'Meta x Realizado x Previsto'),
            new FormatoGrafico(ModeloGrafico.VolumetriaTipoCheckup.toString(), 'Volumetria de tipo de checkups')
        ];

        return modelos;
    }

    getVolumetriaAvaliacao(dataInicial: string, dataFinal: string, idRegional: string, idLoja: string, idDiretor: string): Observable<any>  {
        return this.http.get<GraficoVolumetriaAvaliacao>(`${environment.apiUrl}/Grafico/GetVolumetriaAvaliacao/${dataInicial}/${dataFinal}/${idRegional}/${idLoja}/${idDiretor}`);
    }

    getMeses(): Observable<any>  {
        return this.http.get<Mes[]>(`${environment.apiUrl}/Grafico/GetMeses`);
    }

    getMetaRealizadoPrevisto(idMes: string, ano: string, idRegional: string, idLoja: string, idDiretor: string): Observable<any>  {
        return this.http.get<GraficoMetaRealizadoPrevisto[]>(`${environment.apiUrl}/Grafico/GetMetaRealizadoPrevisto/${idMes}/${ano}/${idRegional}/${idLoja}/${idDiretor}`);
    }

    getVolumetriaTipoCheckup(idMes: string, ano: string, idRegional: string, idLoja: string, idDiretor: string): Observable<any>  {
        return this.http.get<GraficoVolumetriaTipoCheckup[]>(`${environment.apiUrl}/Grafico/GetVolumetriaTipoCheckup/${idMes}/${ano}/${idRegional}/${idLoja}/${idDiretor}`);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) { }
}
