import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PopupMessagesComponent } from 'src/app/_components/popup-messages/popup-messages.component';
import { AlertService, AuthenticationService, GrupoService, CampoService, RegistroCadastroService, RegionalService, LojaService, FuncionarioService, IdentificadorCadastroService } from '../../../_services';
import { Campo, Regional, Grupo, Message, Loja, Funcionario, User } from 'src/app/_models';
import { MatDialog } from '@angular/material';
import { RegistroCadastroInsert } from 'src/app/_models/RegistroCadastroInsert';
import { ToastrService } from 'ngx-toastr';
import { stringToNumber } from '@syncfusion/ej2-charts/src/sparkline/utils/helper';

export interface QtdItensPorGrupo {
  idGrupo: string;
  id: number;
  valor: number;
  tipo: string;
}

export interface QuadroVal {
  titulo: string;
  media: string;
}

export interface GrupoTipo {
  id: string,
  titulo: string;
}

export interface CampoTipo {
  id: string;
  idGrupo: string;
  idTipoCampo: string;
  ordem: string;
  obrigatorio: string;
  descricao: string;
  value: string;
  formControlName: string;
}

export interface RegistroCadastro {
  idCampo: number;
  idIdentificadorCadastro: number;
  valor: string;
}

export interface GrupoErroValidacao {
  id: string;
  descricao: string;
}

@Component({
  selector: 'app-grupo-perguntas',
  templateUrl: './grupo-perguntas.component.html',
  styleUrls: ['./grupo-perguntas.component.css'],
})

export class GrupoPerguntasComponent implements OnInit {



  /*Propriedades*/
  AceleraForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  hide = true;
  listaGrupos = new Array<Grupo>();
  listaCampo = new Array<Campo>();
  listaRegistroCadastro = new Array<RegistroCadastro>();
  listaNomesCampoInvalido = new Array<GrupoErroValidacao>();
  currentUser: User;
  txtGerenteDesabilitado = true;
  cmbRegionalValor: string = '0';
  cmbLojaValor: string = '0';
  cmbGerenteValor: string = '0';
  cmbCorretorValor: string = '0';
  cmbRegionalDesabilitado = true;
  cmbLojaDesabilitado = true;
  cmbGerenteDesabilitado = true;
  cmbCorretorDesabilitado = true;
  regionais: Regional[] = [];
  lojas: Loja[] = [];
  gerentes: Funcionario[] = [];
  corretores: Funcionario[] = [];
  StatusNotaAtendimento = '';
  StatusValResultado = '';
  quadroVal: QuadroVal[] = [];
  grupoTipo: GrupoTipo[] = [];
  campoTipo: CampoTipo[] = [];
  registroCadastro: RegistroCadastro[] = [];
  qtdItensPorGrupo: QtdItensPorGrupo[] = [];
  NotaAtendimento = null;
  ValResultado = '';
  listRadioClick: [string] = [' '];
  tituloForm = JSON.parse(localStorage.getItem('currentForm')).descricao;

  private messageModel: Message;
  get f() { return this.AceleraForm.controls; }

  updateValue(item) {
    return (+(<HTMLInputElement>document.getElementById("p" + item + "Media")).innerText * 10);
  }



  /*Construtores*/
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private apiGrupoService: GrupoService,
    private apiCampoService: CampoService,
    private apiRegistroCadastroService: RegistroCadastroService,
    private apiIdentificadorCadastroService: IdentificadorCadastroService,
    private apiRegionalService: RegionalService,
    private apiLojaService: LojaService,
    private apiFuncionarioService: FuncionarioService,
    private toast: ToastrService,
    private dialog?: MatDialog
  ) { }



  /*Métodos privados*/
  private exibirMensagemAlerta() {
    var msgFormatatada = this.obterMensagemFormatada();
    this.toast.warning(msgFormatatada, "Não foi possível enviar check-up!");
    this.limparListaNomeCampoInvalido();
  }

  private exibirMensagemSucesso() {
    this.toast.success("Os dados foram salvos com sucesso.", "Sucesso!");
    this.limparListaNomeCampoInvalido();
  }

  private fecharMensagens() {
    this.toast.clear();
  }

  private obterMensagemFormatada(): string {
    var msgFormatada = "<div>As seguintes perguntas não foram preenchidas: <br />";
    this.listaNomesCampoInvalido.forEach(function (grupoErroValidacao) {
      msgFormatada += "<br />*" + grupoErroValidacao.descricao;
    });
    msgFormatada += "</div>";

    return msgFormatada;
  }

  private limparListaNomeCampoInvalido() {
    this.listaNomesCampoInvalido = new Array<GrupoErroValidacao>();
  }

  private adicionarNaListaNomeCampoInvalido(idCampo: string, descricaoCampo: string) {
    this.listaNomesCampoInvalido.push({
      id: idCampo,
      descricao: descricaoCampo
    });
  }

  private ordenarListaCampoInvalido() {
    this.listaNomesCampoInvalido.sort(function compare(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }

  private carregarComboRegional(): void {
    this.loading = true;
    this.apiRegionalService.getByPerfilLogin()
      .subscribe(
        rtmc => this.regionais = rtmc.data,
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
        , () => {
          this.habilitarComboRegional();
          this.loading = false;
        });
  }

  private carregarComboLoja(): void {
    this.loading = true;
    this.apiLojaService.getByPerfilLoginRegional(this.cmbRegionalValor)
      .subscribe(
        rtmc => this.lojas = rtmc.data,
        error => {
          this.alertService.error(error);
          this.tratarVisibilidadeLoja();
          this.loading = false;
        }
        , () => {
          this.tratarVisibilidadeLoja();
          this.loading = false;
        });
  }

  private carregarComboGerente(): void {
    this.loading = true;
    this.apiFuncionarioService.getGerentesByLoja(this.cmbLojaValor)
      .subscribe(
        rtmc => this.gerentes = rtmc.data,
        error => {
          this.alertService.error(error);
          this.tratarVisibilidadeGerente();
          this.loading = false;
        }
        , () => {
          this.definirSelecaoComboGerente();
          this.tratarVisibilidadeGerente();
          this.loading = false;
        });
  }

  private carregarComboCorretor(): void {
    this.loading = true;
    this.apiFuncionarioService.getCorretoresByLoja(this.cmbLojaValor)
      .subscribe(
        rtmc => this.corretores = rtmc.data,
        error => {
          this.alertService.error(error);
          this.tratarVisibilidadeCorretor();
          this.loading = false;
        }
        , () => {
          this.tratarVisibilidadeCorretor();
          this.loading = false;
        });
  }

  private habilitarComboRegional(): void {
    this.cmbRegionalDesabilitado = false;
  }

  private habilitarComboLoja(): void {
    this.cmbLojaDesabilitado = false;
  }

  private habilitarComboGerente(): void {
    this.cmbGerenteDesabilitado = false;
  }

  private habilitarComboCorretor(): void {
    this.cmbCorretorDesabilitado = false;
  }

  private desabilitarComboLoja(): void {
    this.cmbLojaDesabilitado = true;
  }

  private desabilitarComboGerente(): void {
    this.cmbGerenteDesabilitado = true;
  }

  private desabilitarComboCorretor(): void {
    this.cmbCorretorDesabilitado = true;
  }

  private tratarVisibilidadeLoja(): void {
    if (this.cmbRegionalValor == '0') {
      this.desabilitarComboLoja();
    }
    else {
      this.habilitarComboLoja();
    }
  }

  private tratarVisibilidadeGerente(): void {
    if (this.cmbLojaValor == '0') {
      this.desabilitarComboGerente();
    }
    else {
      this.habilitarComboGerente();
    }
  }

  private tratarVisibilidadeCorretor(): void {
    if (this.cmbLojaValor == '0') {
      this.desabilitarComboCorretor();
    }
    else {
      this.habilitarComboCorretor();
    }
  }

  private limparComboLoja(): void {
    if (this.cmbRegionalValor == '0') {
      this.lojas = [];
      this.AceleraForm.get('cmbLoja').reset();
    }
  }

  private limparComboGerente(): void {
    if (this.cmbLojaValor == '0') {
      this.gerentes = [];
      this.AceleraForm.get('cmbGerente').reset();
    }
  }

  private limparComboCorretor(): void {
    if (this.cmbLojaValor == '0') {
      this.corretores = [];
      this.AceleraForm.get('cmbCorretor').reset();
    }
  }

  private definirSelecaoPadraoCombo(nomeCombo: string): void {
    this.AceleraForm.get(nomeCombo).setValue('0');
  }

  private definirSelecaoComboGerente(): void {
    if (this.gerentes != null && this.gerentes.length > 0) {
      let primeiroGerente = this.gerentes[0];
      this.AceleraForm.get("cmbGerente").setValue(primeiroGerente.id);
    }
  }

  private obterNomeFormGrupoCombo(idTipoCampo: string, id: string): string {
    if (idTipoCampo == "7") {
      return "cmbRegional";
    }
    else if (idTipoCampo == "8") {
      return "cmbLoja";
    }
    else if (idTipoCampo == "12") {
      return "cmbGerente";
    }
    else if (idTipoCampo == "11") {
      return "cmbCorretor";
    }
    else {
      return 'c' + id;
    }
  }



  /*Métodos públicos*/
  tratarEventoComboRegional(): void {
    this.limparComboLoja();
    this.limparComboGerente();
    this.limparComboCorretor();
    this.definirSelecaoPadraoCombo('cmbLoja');
    this.definirSelecaoPadraoCombo('cmbGerente');
    this.definirSelecaoPadraoCombo('cmbCorretor');
    this.carregarComboLoja();
    this.desabilitarComboGerente();
    this.desabilitarComboCorretor();
  }

  tratarEventoComboLoja(): void {
    this.limparComboGerente();
    this.limparComboCorretor();
    this.definirSelecaoPadraoCombo('cmbGerente');
    this.definirSelecaoPadraoCombo('cmbCorretor');
    this.carregarComboGerente();
    this.carregarComboCorretor();
  }



  /*Eventos*/
  ngOnInit() {
    this.loading = true;
    this.AceleraForm = this.formBuilder.group({
      ['*']: ['', Validators.required]
    });

    (<HTMLInputElement>document.getElementById("hora")).innerHTML = "0";
    (<HTMLInputElement>document.getElementById("segundo")).innerHTML = "0";
    (<HTMLInputElement>document.getElementById("minuto")).innerHTML = "0";

    this.apiGrupoService.getGrupo(JSON.parse(localStorage.getItem('currentForm')).id)
      .subscribe(
        rtm => this.listaGrupos = rtm.data,
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
        , () => {
          var contador = +0;
          this.listaGrupos.forEach(element => {
            //Carregar Campos
            this.apiCampoService.getCampo(+element.id)
              .subscribe(
                rtmc => this.listaCampo = rtmc.data,
                error => {
                  this.alertService.error(error);
                  this.loading = false;
                }
                , () => {
                  this.listaCampo.forEach(elementCampo => {

                    let formControlNameTratado = this.obterNomeFormGrupoCombo(elementCampo.idTipoCampo, elementCampo.id);

                    this.campoTipo.push({
                      id: elementCampo.id,
                      idGrupo: elementCampo.idGrupo,
                      idTipoCampo: elementCampo.idTipoCampo,
                      ordem: elementCampo.ordem,
                      obrigatorio: elementCampo.obrigatorio,
                      descricao: elementCampo.descricao,
                      value: elementCampo.value,
                      formControlName: formControlNameTratado
                    });

                    if (elementCampo.obrigatorio) {
                      this.AceleraForm.addControl(formControlNameTratado, new FormControl('', Validators.required));
                    }
                    else {
                      this.AceleraForm.addControl(formControlNameTratado, new FormControl('', Validators.nullValidator));
                    }

                  });
                });
            this.grupoTipo.push({ id: element.id, titulo: element.descricao })

            if (element.descricao != 'Perguntas' && element.descricao != 'Feedback') {
              contador++;
              this.quadroVal.push({ titulo: element.descricao, media: 'p' + element.id + 'Media' })
            }
          });
        });

    this.loading = false;

    this.carregarComboRegional();
  }

  onSubmit() {
    this.fecharMensagens();
    this.loading = true;
    this.submitted = true;
    var listaValida = true;
    this.registroCadastro = [];

    this.campoTipo.forEach(element => {

      if (element.idTipoCampo == '10') {
        this.registroCadastro.push({
          idCampo: +element.id,
          idIdentificadorCadastro: 0,
          valor: (<HTMLInputElement>document.getElementById("hora")).innerHTML + (<HTMLInputElement>document.getElementById("minuto")).innerHTML + (<HTMLInputElement>document.getElementById("segundo")).innerHTML
        });
      }
      else if (element.idTipoCampo == "7" || element.idTipoCampo == "8" || element.idTipoCampo == "12" || element.idTipoCampo == "11") {
        let valorCombo = this.AceleraForm.controls[element.formControlName].value;
        if (valorCombo != null && valorCombo != 0 && valorCombo != "0") {
          this.registroCadastro.push({
            idCampo: +element.id,
            idIdentificadorCadastro: 0,
            valor: this.AceleraForm.controls[element.formControlName].value
          });
        }
        else {
          listaValida = false;
          this.adicionarNaListaNomeCampoInvalido(element.id, element.descricao);
          this.ordenarListaCampoInvalido();
        }
      }
      else if (element.idTipoCampo != "9" && this.AceleraForm.controls[element.formControlName].value != '') {
        this.registroCadastro.push({
          idCampo: +element.id,
          idIdentificadorCadastro: 0,
          valor: this.AceleraForm.controls[element.formControlName].value
        });
      }
      else if (element.obrigatorio && this.AceleraForm.controls[element.formControlName].value == '') {
        listaValida = false;
        this.adicionarNaListaNomeCampoInvalido(element.id, element.descricao);
        this.ordenarListaCampoInvalido();
      }
    });

    if (listaValida) {
      //this.exibirMensagemSucesso();

      let idIdentificadorCadastro = 0;
      let registroCadastroInsert = new RegistroCadastroInsert();
      registroCadastroInsert.registroCadastro = this.registroCadastro;
      registroCadastroInsert.IdIdentificadorCadastro = idIdentificadorCadastro;
      this.apiRegistroCadastroService.getRegistroCadastro(registroCadastroInsert)
        .subscribe(
          data => {   
            this.loading = false;
            if (data.success) {
              let commandResult = data.data;
              if (commandResult.success) {
                this.messageModel = new Message(
                  "Itens inseridos",
                  "Cadastrado com sucesso!",
                  "",
                  "sucess");
                this.dialog.open(PopupMessagesComponent, { data: this.messageModel });
                this.router.navigate(['/checkup/home']);
              }
              else {
                this.alertService.error(commandResult.message);
                this.messageModel = new Message(
                  "Falha ao inserir",
                  commandResult.message,
                  "",
                  "error");
                this.dialog.open(PopupMessagesComponent, { data: this.messageModel });
              }
            }
          });
    }
    else {
      this.exibirMensagemAlerta();
      this.loading = false;
      return
    }
  }

  onClickRadio(idItem: number, qtdpts: number, pergunta: string, tipo: string, idGrupo: string) {
    let valorExcedente = 0;
    let mediaFinal = 0;
    if (this.qtdItensPorGrupo.filter(x => x.id == idItem).length > 0) {
      let index: number = this.qtdItensPorGrupo.findIndex(x => x.id == idItem);
      this.qtdItensPorGrupo.splice(index, 1);
    }

    this.qtdItensPorGrupo.push({ id: idItem, idGrupo: idGrupo, valor: qtdpts, tipo: tipo })

    let qtdSim = this.qtdItensPorGrupo.filter(x => x.tipo == 's' && x.idGrupo == idGrupo).length;
    let qtdNao = this.qtdItensPorGrupo.filter(x => x.tipo == 'n' && x.idGrupo == idGrupo).length;

    for (let item of this.qtdItensPorGrupo.filter(x => x.tipo == 'd' && x.idGrupo == idGrupo)) {
      let mediaNSP = +item.valor / (qtdSim + qtdNao);
      valorExcedente = +valorExcedente + +mediaNSP;
    }

    for (let item of this.qtdItensPorGrupo.filter(x => x.tipo == 's' && x.idGrupo == idGrupo)) {
      mediaFinal = +mediaFinal + +item.valor + +valorExcedente;
    }

    var qtdItens = 0;
    if (this.campoTipo.filter(x => x.idGrupo == idGrupo && (x.idTipoCampo == '5' ||
      x.idTipoCampo == '13')).length == this.qtdItensPorGrupo.filter(x => x.tipo == 'd' && x.idGrupo == idGrupo).length) {
      (<HTMLInputElement>document.getElementById('quadro_p' + idGrupo + "Media")).style.display = "none";
      (<HTMLInputElement>document.getElementById('p' + idGrupo + "Media")).style.display = "none";
      qtdItens -= 1;
    }
    else {
      (<HTMLInputElement>document.getElementById('quadro_p' + idGrupo + "Media")).style.display = "";
      (<HTMLInputElement>document.getElementById('p' + idGrupo + "Media")).style.display = "";
    }

    (<HTMLInputElement>document.getElementById('p' + idGrupo + "qtdProgressBar")).innerText = mediaFinal.toFixed(2) + '';
    (<HTMLInputElement>document.getElementById('p' + idGrupo + "Media")).innerText = mediaFinal.toFixed(2) + '';


    var somaItens = 0;
    this.quadroVal.forEach(element => {
      somaItens += +(<HTMLInputElement>document.getElementById(element.media)).innerText;
      qtdItens++;
    });

    var Media = somaItens / qtdItens;

    this['NotaAtendimento'] = parseFloat(Media.toFixed(2));

    if (Media == 10) {
      this.StatusNotaAtendimento = 'text-success';
      this.StatusValResultado = 'text-success';
      this['ValResultado'] = 'Ótimo';
    }
    else if (Media >= 8) {
      this.StatusNotaAtendimento = 'text-primary';
      this.StatusValResultado = 'text-primary';
      this['ValResultado'] = 'Ótimo';
    }
    else if (Media >= 6) {
      this.StatusNotaAtendimento = 'text-primary';
      this.StatusValResultado = 'text-primary';
      this['ValResultado'] = 'Bom';
    }
    else if (Media >= 4) {
      this.StatusNotaAtendimento = 'text-orange';
      this.StatusValResultado = 'text-orange';
      this['ValResultado'] = 'Regular';
    }
    else if (Media >= 2) {
      this.StatusNotaAtendimento = 'text-danger';
      this.StatusValResultado = 'text-danger';
      this['ValResultado'] = 'Fraco';
    }
    else {
      this.StatusNotaAtendimento = 'text-danger';
      this['StatusValResultado'] = 'text-danger';
      this['ValResultado'] = 'Péssimo';
    }
  }

}




var s = 1;
var m = 0;
var h = 0;
setInterval(() => {
  if (<HTMLInputElement>document.getElementById("hora")) {
    if (s == 60) { m++; s = 0; }
    if (m == 60) { h++; s = 0; m = 0; }
    if (h < 10) (<HTMLInputElement>document.getElementById("hora")).innerHTML = "0" + h + ":"; else (<HTMLInputElement>document.getElementById("hora")).innerHTML = h + ":";
    if (s < 10) (<HTMLInputElement>document.getElementById("segundo")).innerHTML = "0" + s; else (<HTMLInputElement>document.getElementById("segundo")).innerHTML = s + "";
    if (m < 10) (<HTMLInputElement>document.getElementById("minuto")).innerHTML = "0" + m + ":"; else (<HTMLInputElement>document.getElementById("minuto")).innerHTML = m + ":";
    s++;
    if (screen.width < 1024 || screen.height < 768) {
      (<HTMLInputElement>document.getElementById("placarMobileCK")).innerHTML = (<HTMLInputElement>document.getElementById("placarCK")).innerHTML.replace("fixed", "").replace('_ngcontent-c12=""', '');
      (<HTMLInputElement>document.getElementById("placarCK")).style.display = 'none';
      (<HTMLInputElement>document.getElementById("placarMobileCK")).style.display = 'block';
    }
    else {
      (<HTMLInputElement>document.getElementById("placarCK")).style.display = 'block';
      (<HTMLInputElement>document.getElementById("placarMobileCK")).style.display = 'none';
    }
  }
}
  , 1000)