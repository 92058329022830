import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @ViewChild('file') file: ElementRef;
  public progress: number;
  public message: string;
  formData: FormData;
  constructor(private http: HttpClient) { 
    this.formData = new FormData();
    // this.protocolo.LstUploadFiles = new Array<string>();
    // this.protocolo.FormDataUpload = new FormData();
  }

  loadFiles() {
    var files = this.file.nativeElement.files;
    
    
    
    if (files.length === 0)
      return;

    //const formData = new FormData();



    for (let file of files) {
      this.formData.append(file.name, file);
    }
  }

  removeFile(fileParam){
    for (let file of this.file.nativeElement.files) {
      if (file.name == fileParam.name)
        this.file.nativeElement.files = this.file.nativeElement.files.filter(obj => obj !== fileParam);
    }
  }

  ngOnInit() {
  }

}
