import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-panel-collapse',
  templateUrl: './panal-collapse.component.html',
  styleUrls: ['./panal-collapse.component.css']
})
export class PanalCollapseComponent implements OnInit {
  @Input() Title : string;
  @Input() Expanded: string;
  @Input() ExpandedEvent: string;


  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  randonId : string;

  constructor() { }

  ngOnInit() {
    this.setRandomId();
    
    $(document).on('click', '#buttonCollapse', function(e){
      var $this = $('#buttonCollapse');
      $this.children().first().toggleClass('fa fa-angle-up').toggleClass('fa fa-angle-down');
    });
  }

  getRandomId() {
    return Math.floor((Math.random()*6)+1);
  }

  setRandomId(){
    this.randonId = (typeof this.randonId === 'undefined') ? this.getRandomId().toString() : this.randonId; 
  }

}
