﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../globals';
import { Observable, of } from 'rxjs';
import { User } from '../_models';
import { environment } from '../../environments/environment'

@Injectable()
export class UserService {
    private apiUrl: string;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrlLogin}/users`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrlLogin}/users/` + id);
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrlLogin}/users/register`, user);
    }

    update(user: User) {
        return this.http.put(`${environment.apiUrlLogin}/users/` + user.id, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrlLogin}/users/` + id);
    }
}