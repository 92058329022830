import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../_models/user';
import { Router } from '@angular/router';
import { TipoMenu } from 'src/app/_enums/TipoMenu';
import { TipoFormulario } from 'src/app/_enums/TipoFormulario';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() tipoMenu: number;
  public currentUser: User;
  public menuEhHome: boolean = false;
  public menuEhCheckup: boolean = false;
  public menuEhMov: boolean = false;
  public tipoForm = TipoFormulario;

  constructor(private router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));    
   }

  ngOnInit() {   
    this.definirMenu(); 
  }

  private definirMenu(): void {
    switch(this.tipoMenu) { 
      case TipoMenu.Home: { 
        this.definirPropriedadesHome();
        break; 
      } 
      case TipoMenu.Checkup: { 
        this.definirPropriedadesCheckup();
        break; 
      } 
      case TipoMenu.Mov: { 
        this.definirPropriedadesMov();
        break; 
      } 
      default: { 
        this.definirPropriedadesPadrao();
        break; 
      } 
   } 
  }

  private definirPropriedadesHome(): void {
    this.menuEhHome = true; 
    this.menuEhCheckup = false; 
    this.menuEhMov = false; 
  }

  private definirPropriedadesCheckup(): void {
    this.menuEhHome = false; 
    this.menuEhCheckup = true; 
    this.menuEhMov = false; 
  }

  private definirPropriedadesMov(): void {
    this.menuEhHome = false; 
    this.menuEhCheckup = false; 
    this.menuEhMov = true; 
  }

  private definirPropriedadesPadrao(): void {
    this.menuEhHome = false; 
    this.menuEhCheckup = false; 
    this.menuEhMov = false; 
  }
}
