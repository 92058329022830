import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, FuncionarioService } from "src/app/_services";
import { MovService } from "src/app/_services/mov.service";
import { PopConteudo } from "src/app/_models/PopConteudo";
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { PopConteudoUpdate } from "src/app/_models/PopConteudoUpdate";


@Component({
  selector: 'text-editor-mov',
  templateUrl: './text-editor-mov.component.html',
  styleUrls: ['./text-editor-mov.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService]
})

export class TextEditorMovComponent implements OnInit, OnDestroy {

  /* Propriedades */
  @ViewChild('rtePop') public rtePop: RichTextEditorComponent;
  private id: number;
  public loading = false;
  public popConteudo: PopConteudo;
  public htmlContent = "";
  public subtitulo = "";
  public btnAtualizDisabled = true;
  public funcionarioLogadoPodeEditar = false;
  public tools: object = { type: 'MultiRow', items: [] };
  public insertImageSettings: object = {
    allowedTypes: ['.jpg', '.png'],
    saveFormat: "Base64"
  };
  public pasteCleanupSettings: object = {
    prompt: true,
    plainText: true,
    keepFormat: true,
    deniedTags: ['a', 'img[src]'],
    deniedAttrs: ['class', 'title', 'id'],
    allowedStyleProps: ['color', 'margin', 'font-size']
  };



  /* Construtor */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private apiMovService: MovService,
    private apiFuncionarioService: FuncionarioService,
    private toast: ToastrService) {
    this.route.queryParams.subscribe(params => {
      let idParam = this.route.snapshot.paramMap.get('idPopConteudo');
      this.id = Number(idParam);
    });
  }



  /* Métodos privados */
  private carregarPopConteudo(): void {
    this.fecharMensagens();
    this.loading = true;
    this.apiMovService.getPopConteudoById(this.id)
      .subscribe(
        rtmc => this.popConteudo = rtmc.data,
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
        , () => {
          this.loading = false;
          if (this.popConteudo != null) {
            this.htmlContent = this.popConteudo.html;
            this.subtitulo = this.popConteudo.subtitulo;
            this.btnAtualizDisabled = false;
          }
          else {
            this.exibirMensagemAlerta("Pop não localizado!", "Verificar os problemas abaixo:");
          }
        });
  }

  private exibirMensagemAlerta(msgAlerta: string, msgTitulo: string) {
    this.toast.warning(msgAlerta, msgTitulo);
  }

  private exibirMensagemSucesso(msgTitulo: string) {
    this.toast.success(msgTitulo);
  }

  private fecharMensagens() {
    this.toast.clear();
  }

  private obterMensagemFormatada(erros: string[]): string {
    var msgFormatada = "<div>Verificar os problemas abaixo: <br />";
    erros.forEach(function (e) {
      msgFormatada += "<br />*" + e;
    });
    msgFormatada += "</div>";

    return msgFormatada;
  }

  private tratarPermissaoEdicaoFuncionLogado(): void {
    this.loading = true;
    this.apiFuncionarioService.getFuncionarioLogadoPodeEditarPop()
      .subscribe(
        rtmc => this.funcionarioLogadoPodeEditar = rtmc.data,
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
        , () => {
          this.loading = false;
          if (this.funcionarioLogadoPodeEditar) {
            this.definirVisibilidadeToolbar(true);
            this.carregarItensToolbarEdicao();
          }
        });
  }

  private carregarItensToolbarEdicao(): void {
    this.tools = {
      type: 'MultiRow',
      items: [
        'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
        'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
        'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
        'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
        'Image', '|', 'ClearFormat', 'Print', '|', 'FullScreen'
      ]
    };
  }

  private definirVisibilidadeToolbar(visivel: boolean): void {
    (<HTMLInputElement>document.getElementById("rtePopId_toolbar_wrapper")).hidden = !visivel;
  }

  private carregarTraducao(): void {
    this.rtePop.localeObj['currentLocale'] = {
      alignments: "Alinhamentos",
      justifyLeft: "Alinhado à esquerda",
      justifyCenter: "Organize no centro",
      justifyRight: "Alinhar à direita",
      justifyFull: "Alinhamento justiça",
      fontName: "Nome da fonte",
      fontSize: "Tamanho da fonte",
      fontColor: "Cor da fonte",
      backgroundColor: "Cor de fundo",
      bold: "Negrito",
      italic: "Itálico",
      underline: "Sublinhado",
      strikethrough: "Tachado",
      clearFormat: "Limpar formatação",
      clearAll: "Excluir tudo",
      cut: "Cortar",
      copy: "Copiar",
      paste: "Colar",
      unorderedList: "Lista com marcadores",
      orderedList: "Lista numerada",
      indent: "Aumentar recuo",
      outdent: "Reduzir recuo",
      undo: "Desfazer",
      redo: "Repetir",
      superscript: "Sobrescrito",
      subscript: "Índice",
      createLink: "Usar hiperlink",
      openLink: "Abrir link",
      editLink: "Editar link",
      removeLink: "Remover link",
      image: "Inserir imagem",
      replace: "Substituir",
      align: "Alinhar",
      caption: "Descrição da imagem",
      remove: "Excluir",
      insertLink: "Inserir link",
      display: "Exibir",
      altText: "texto alternativo",
      dimension: "Redimensionar",
      fullscreen: "Maximizar",
      maximize: "Maximizar",
      minimize: "Minimizar",
      lowerCase: "Letras minúsculas",
      upperCase: "Letras maiúsculas",
      print: "Imprimir",
      formats: "Formatos",
      sourcecode: "Visualização de código",
      preview: "Visualização",
      viewside: "Ver lado",
      insertCode: "Digite o código",
      linkText: "Mostrar texto",
      linkTooltipLabel: "Título",
      linkWebUrl: "Endereço da Web",
      linkTitle: "Digite o título",
      linkurl: "http://example.com",
      linkOpenInNewWindow: "Abrir link em nova janela",
      linkHeader: "Inserir link",
      dialogInsert: "Colar",
      dialogCancel: "Cancelar",
      dialogUpdate: "Atualizar",
      imageHeader: "Inserir imagem",
      imageLinkHeader: "Você também pode fornecer um link da web",
      mdimageLink: "Forneça um URL para sua foto",
      imageUploadMessage: "Coloque a foto aqui ou faça o upload",
      imageDeviceUploadMessage: "Clique aqui para enviar",
      imageAlternateText: "Texto alternativo",
      alternateHeader: "texto alternativo",
      browse: "Pesquisa",
      imageUrl: "http://example.com/image.png",
      imageCaption: "Legenda",
      imageSizeHeader: "Tamanho da imagem",
      imageHeight: "Altura",
      imageWidth: "Largura",
      textPlaceholder: "Inserir texto",
      inserttablebtn: "Inserir tabela",
      tabledialogHeader: "Inserir tabela",
      tableWidth: "Largura",
      cellpadding: "Preenchimento celular",
      cellspacing: "Espaçamento celular",
      columns: "Número de colunas",
      rows: "Número de linhas",
      tableRows: "Linhas da tabela",
      tableColumns: "Colunas da tabela",
      tableCellHorizontalAlign: "Alinhamento horizontal da célula da tabela",
      tableCellVerticalAlign: "Alinhamento vertical da célula da tabela",
      createTable: "Criar tabela",
      removeTable: "Remover tabela",
      tableHeader: "Cabeçalho da tabela",
      tableRemove: "Remover tabela",
      tableCellBackground: "Fundo da célula da tabela",
      tableEditProperties: "Propriedades da edição da tabela",
      styles: "Estilos",
      insertColumnLeft: "Inserir coluna à esquerda",
      insertColumnRight: "Inserir coluna à direita",
      deleteColumn: "Excluir coluna",
      insertRowBefore: "Inserir linha antes",
      insertRowAfter: "Inserir linha depois",
      deleteRow: "Excluir linha",
      tableEditHeader: "Editar tabela",
      TableHeadingText: "Título",
      TableColText: "Coluna",
      imageInsertLinkHeader: "Inserir link",
      editImageHeader: "Editar imagem",
      alignmentsDropDownLeft: "Alinhar à esquerda",
      alignmentsDropDownCenter: "Alinhar ao centro",
      alignmentsDropDownRight: "Alinhar à direita",
      alignmentsDropDownJustify: "Alinhamento justificado",
      imageDisplayDropDownInline: "Na linha",
      imageDisplayDropDownBreak: "Pausa",
      tableInsertRowDropDownBefore: "Inserir linha antes",
      tableInsertRowDropDownAfter: "Inserir linha depois",
      tableInsertRowDropDownDelete: "Excluir linha",
      tableInsertColumnDropDownLeft: "Inserir coluna à esquerda",
      tableInsertColumnDropDownRight: "Inserir coluna à direita",
      tableInsertColumnDropDownDelete: "Excluir coluna",
      tableVerticalAlignDropDownTop: "Alinhar parte superior",
      tableVerticalAlignDropDownMiddle: "Alinhar ao centro",
      tableVerticalAlignDropDownBottom: "Alinhar abaixo",
      tableStylesDropDownDashedBorder: "Fronteiras tracejadas",
      tableStylesDropDownAlternateRows: "Linhas alternativas",
      pasteFormat: "Opções de colagem",
      pasteFormatContent: "Selecione a ação de formatação",
      plainText: "Texto simples",
      cleanFormat: "Formato limpo",
      keepFormat: "Manter formato",
      pasteDialogCancel: "Cancelar",
      pasteDialogOk: "Concluir",
      swatches: "Amostras",
      paragraph: "Parágrafo",
      quotation: "Citação",
      heading1: "Cabeçalho nível 1",
      heading2: "Cabeçalho nível 2",
      heading3: "Cabeçalho nível 3",
      heading4: "Cabeçalho nível 4",
      heading5: "Cabeçalho nível 5",
      heading6: "Cabeçalho nível 6"
    };

  }



  /* Métodos públicos */
  public atualizarPopConteudo(): void {
    this.fecharMensagens();
    let popConteudoUpdate = new PopConteudoUpdate(this.id, this.htmlContent);
    this.loading = true;
    this.apiMovService.putPopConteudo(popConteudoUpdate)
      .subscribe(
        data => {
          this.loading = false;
          if (data.success) {
            let commandResult = data.data;
            if (commandResult.success) {
              this.exibirMensagemSucesso("Conteúdo atualizado com sucesso!");
            }
            else {
              let msgsErro = this.obterMensagemFormatada(commandResult.data)
              this.exibirMensagemAlerta(msgsErro, "Não foi possível atualizar o conteúdo!");
            }
          }
        });
  }



  /* Eventos */
  ngOnInit(): void {
    this.fecharMensagens();
    this.tratarPermissaoEdicaoFuncionLogado();
    this.carregarPopConteudo();
  }
  ngOnDestroy() {
    this.fecharMensagens();
  }
  ngAfterViewInit() {
    this.definirVisibilidadeToolbar(false);
    this.carregarTraducao();
  }

}