import { Campo } from '../_models/Campo';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})

export class CampoService {

    getCampo(codGrupo: number): Observable<any> {
        return this.http.get<Campo[]>(`${environment.apiUrl}/Campo/GetBloco/`+codGrupo);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) { }
}
