import { Component, OnInit, Inject } from '@angular/core';
import { AlertService, FormularioService } from '../../../_services';
import { Formulario } from 'src/app/_models';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';

export interface FormLista {
  id: number;
  text: string;
  img: string;
  link: string;
}

/**
 * @title Dynamic grid-list
 */
@Component({
  selector: 'app-lista-formularios',
  templateUrl: './lista-formularios.component.html',
  styleUrls: ['./lista-formularios.component.css']
})

export class ListaFormulariosComponent implements OnInit {
  listaFormulario = new Array<Formulario>();
  form = new Formulario();
  loading = false;
  constructor(
    private apiFormularioService: FormularioService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  formLista: FormLista[] = [];

  ngOnInit() {
    this.loading = true;
    this.apiFormularioService.getFormulario()
      .subscribe(
        rtmc => this.listaFormulario = rtmc.data.result,
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
        , () => {
          this.listaFormulario.forEach(elementform => {
            this.formLista.push({
              id: elementform.id,
              text: elementform.descricao,
              img: 'assets/images/'+elementform.img,
              link: elementform.link,
            })
          });
          this.loading = false;
        });
  }

  onClickForm(id: number, link: string, descricao: string) {
    this.form.id = id;
    this.form.descricao = descricao;
    this.form.link = '/checkup/'+link;

    localStorage.setItem('currentForm', JSON.stringify(this.form));
    this.router.navigate([this.form.link]);
  }
}

