﻿import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { HomePageComponent } from './_pages/home-page/home-page.component';
import { LoginPageComponent } from './_pages/login-page/login-page.component';
import { RegisterPageComponent } from './_pages/register-page/register-page.component';
import { VendasChatComponent } from './_pages/checkup/vendas-chat/vendas-chat.component';
import { VendasPresencialComponent } from './_pages/checkup/vendas-presencial/vendas-presencial.component';
import { VendasTelefoneComponent } from './_pages/checkup/vendas-telefone/vendas-telefone.component';
import { ListaRegistroComponent } from './_pages/checkup/lista-registro/lista-registro.component';
import { ListaDetalheRegistroComponent } from './_pages/checkup/lista-detalhe-registro/lista-detalhe-registro.component';
import { GraficosComponent } from './_pages/checkup/graficos/graficos.component';
import { AuthGuard } from './_guards';
import { PopupMessagesComponent } from './_components/popup-messages/popup-messages.component';
import { HomePageCheckupComponent } from './_pages/checkup/home-page-checkup/home-page-checkup.component';
import { HomePageMovComponent } from './_pages/mov/home-page-mov/home-page-mov.component';
import { PopPageMovComponent } from './_pages/mov/pop-page-mov/pop-page-mov.component';
import { RelatoriosComponent } from './_pages/checkup/relatorios/relatorios.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'register', component: RegisterPageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'popupMessage', component: PopupMessagesComponent },
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  /*Módulo checkup*/
  { path: 'checkup/home', component: HomePageCheckupComponent, canActivate: [AuthGuard] },
  { path: 'checkup', component: HomePageCheckupComponent, canActivate: [AuthGuard] },
  { path: 'checkup/vendasChat', component: VendasChatComponent, canActivate: [AuthGuard] },
  { path: 'checkup/vendasPresencial', component: VendasPresencialComponent, canActivate: [AuthGuard] },
  { path: 'checkup/vendasTelefone', component: VendasTelefoneComponent, canActivate: [AuthGuard] },
  { path: 'checkup/listaRegistro', component: ListaRegistroComponent, canActivate: [AuthGuard] },
  { path: 'checkup/listaDetalheRegistro', component: ListaDetalheRegistroComponent, canActivate: [AuthGuard] },
  { path: 'checkup/graficos', component: GraficosComponent, canActivate: [AuthGuard] },
  { path: 'checkup/relatorios', component: RelatoriosComponent, canActivate: [AuthGuard] },
  /*Módulo mov*/
  { path: 'mov/home', component: HomePageMovComponent, canActivate: [AuthGuard] },
  { path: 'mov', component: HomePageMovComponent, canActivate: [AuthGuard] },
  { path: 'mov/pop/:idPopConteudo', component: PopPageMovComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '/home' }
];

// export const routing = RouterModule.forRoot(appRoutes);
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRouting { }