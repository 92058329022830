import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import { environment } from '../../environments/environment'
import { RelatorioExcelCompleto } from '../_models/RelatorioExcelCompleto';

@Injectable({
    providedIn: 'root'
})

export class RelatorioService {

    getExcelCompleto(dataInicial: string, dataFinal: string): Observable<any> {
        return this.http.get<RelatorioExcelCompleto[]>(`${environment.apiUrl}/Relatorio/GetExcelCompleto/${dataInicial}/${dataFinal}`);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) {
    }
}