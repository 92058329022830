﻿import { Component, OnInit } from '@angular/core';
import { User } from '../../../_models/user';

@Component({
    selector: 'header-mov',
    templateUrl: 'header-mov.component.html',
    styleUrls: ['header-mov.component.css']
})

export class HeaderMovComponent implements OnInit {

    /* Propriedades */
    private dayname = new Array("Domingo", "Segunda-feira", "Terça-feira", "Quarta-Feira", "Quinta-feira", "Sexta-feira", "Sábado");
    private monName = new Array("janeiro", "fevereiro", "março", "abril", "Maio", "junho", "agosto", "outubro", "novembro", "dezembro");
    public currentUser: User;
    public currentDate: string;

    /* Construtor */
    constructor() {
        let dateNow = new Date();
        this.currentDate = `${this.dayname[dateNow.getDay()]}, ${dateNow.getDate()} do ${dateNow.getMonth() + 1} de ${dateNow.getFullYear()}.`;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    /* Métodos privados */

    /* Métodos públicos */

    /* Eventos */
    ngOnInit() {
    }
}
