import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';

@Component({
  selector: 'app-home-page-checkup',
  templateUrl: './home-page-checkup.component.html'
})
export class HomePageCheckupComponent implements OnInit {

  public tipoMenuValue: number = TipoMenu.Checkup;

  constructor() { }

  ngOnInit() {
  }

}