import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConfigService {
  readonly apiUrl: string;
  readonly apiUrlLogin: string;
  readonly production: boolean;
  constructor() {
    this.apiUrl = environment.apiUrl;
    this.apiUrlLogin = environment.apiUrlLogin;
    this.production = environment.production;
  }
}
