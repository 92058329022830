import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import { environment } from '../../environments/environment'
import { Responsavel} from '../_models';

@Injectable({
    providedIn: 'root'
})

export class UsuariosResponsaveisService {

    getResponsaveis(): Observable<any>{
        let apiURL = `${environment.apiUrl}/api/Usuario`;
        return this.http.get<Responsavel[]>(apiURL);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) { }


    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead

            this.alertService.error(`Ocorreu um erro ao buscar as divisões. Detalhes: \n ${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
