import { Grupo } from '../_models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})

export class GrupoService {

    getGrupo(codFormulario: number): Observable<any> {
        return this.http.get<Grupo[]>(`${environment.apiUrl}/Grupo/GetBloco/`+codFormulario);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) { }


}
