
export class CheckupListagem {

  constructor(
      id: string,
      criadoPor: string, 
      alteradoPor: string, 
      criadoEm: Date, 
      perfil: string, 
      nomeCorretor: string, 
      notaGeral: number, 
      piorEtapa: string, 
      melhorEtapa: string, 
      checkup: string, 
      nomeRegional: string) {
		this.id = id;
		this.criadoPor = criadoPor;
		this.alteradoPor = alteradoPor;
		this.criadoEm = criadoEm;
		this.perfil = perfil;
		this.nomeCorretor = nomeCorretor;
		this.notaGeral = notaGeral;
		this.piorEtapa = piorEtapa;
		this.melhorEtapa = melhorEtapa;
		this.checkup = checkup;
		this.nomeRegional = nomeRegional;
	}

  id: string;
  criadoPor: string;
  alteradoPor: string;
  criadoEm: Date;
  alteradoEm?: Date;
  perfil: string;
  nomeCorretor: string;
  notaGeral: number; 
  piorEtapa: string; 
  melhorEtapa: string; 
  checkup: string; 
  nomeRegional: string;  
}