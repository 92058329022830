﻿import { NgModule, Compiler } from '@angular/core';
//import { JitCompiler } from '@angular/compiler';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    CategoryService, 
    LegendService, 
    TooltipService, 
    ColumnSeriesService, 
    BarSeriesService, 
    PieSeriesService, 
    AccumulationChartModule, 
    RadarSeriesService, 
    DataLabelService, 
    LineSeriesService 
} from '@syncfusion/ej2-angular-charts';
   

import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

import { GridModule, PageService, SortService, FilterService, GroupService, ToolbarService, ExcelExportService } from '@syncfusion/ej2-angular-grids';
import {ToastrModule} from 'ngx-toastr';
import { DatePipe } from '@angular/common';

import {
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatExpansionModule,
    MatButtonModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatCheckboxModule ,
    MatChipsModule,
    MAT_DATE_LOCALE,
    MatTreeModule
    
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';

import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TreeViewModule, ContextMenuModule } from '@syncfusion/ej2-angular-navigations';

// used to create fake backend
//import { fakeBackendProvider } from './_helpers';
import { AppComponent } from './app.component';
// import { UploadModule } from './upload/upload.module';
import { AppRouting } from './app.routing';
import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, UserService } from './_services';
import { HomeComponent } from './_components/home/home.component';
import { ListaFormulariosComponent } from './_components/checkup/lista-formularios/lista-formularios.component';
import { DetalhamentoRegistroComponent } from './_components/checkup/detalhamento-registro/detalhamento-registro.component';
import { GrupoPerguntasComponent } from './_components/checkup/grupo-perguntas/grupo-perguntas.component';
import { LoginComponent } from './_components/login/login.component';
import { RegisterComponent } from './_components/register/register.component';
import { RegistrosFormularioComponent } from './_components/checkup/registros-formulario/registros-formulario.component';
import { GraficoMelhorAvaliacaoComponent } from './_components/checkup/grafico/melhor-avaliacao/melhor-avaliacao.component';
import { GraficoVolumetriaAvaliacaoComponent } from './_components/checkup/grafico/volumetria-avaliacao/volumetria-avaliacao.component';
import { GraficoMetaRealizadoPrevistoComponent } from './_components/checkup/grafico/meta-realizado-previsto/meta-realizado-previsto.component';
import { GraficoVolumetriaTipoCheckupComponent } from './_components/checkup/grafico/volumetria-tipo-checkup/volumetria-tipo-checkup.component';
import { MenuGraficoComponent } from './_components/checkup/grafico/menu-grafico/menu-grafico.component';
import { PrincipalGraficoComponent } from './_components/checkup/grafico/principal-grafico/principal-grafico.component';
import { HomeCheckupComponent } from './_components/checkup/home-checkup/home-checkup.component';
import { HomeMovComponent } from './_components/mov/home-mov/home-mov.component';
import { TextEditorMovComponent } from './_components/mov/text-editor-mov/text-editor-mov.component';
import { SummaryMovComponent } from './_components/mov/summary-mov/summary-mov.component';
import { HeaderMovComponent } from './_components/mov/header-mov/header-mov.component';
import { PopMovComponent } from './_components/mov/pop-mov/pop-mov.component';
import { RelatorioExcelCompletoComponent } from './_components/checkup/relatorio/excel-completo/excel-completo.component';

import { RouterModule } from '@angular/router';

//Pages
import { LoginPageComponent } from './_pages/login-page/login-page.component';
import { ListaRegistroComponent } from './_pages/checkup/lista-registro/lista-registro.component';
import { ListaDetalheRegistroComponent } from './_pages/checkup/lista-detalhe-registro/lista-detalhe-registro.component';
import { RegisterPageComponent } from './_pages/register-page/register-page.component';
import { HeaderComponent } from './_components/shared/header/header.component';
import { HomePageComponent } from './_pages/home-page/home-page.component';
import { PanalCollapseComponent } from './_components/shared/panal-collapse/panal-collapse.component';
import { PopupMessagesComponent } from './_components/popup-messages/popup-messages.component';
import { UploadComponent } from './_components/upload/upload.component';
import { SettingsService } from './_services/settings.service';
import { VendasChatComponent } from './_pages/checkup/vendas-chat/vendas-chat.component';
import { VendasPresencialComponent } from './_pages/checkup/vendas-presencial/vendas-presencial.component';
import { VendasTelefoneComponent } from './_pages/checkup/vendas-telefone/vendas-telefone.component';
import { GraficosComponent } from './_pages/checkup/graficos/graficos.component';
import { HomePageCheckupComponent } from './_pages/checkup/home-page-checkup/home-page-checkup.component';
import { HomePageMovComponent } from './_pages/mov/home-page-mov/home-page-mov.component';
import { PopPageMovComponent } from './_pages/mov/pop-page-mov/pop-page-mov.component';
import { RelatoriosComponent } from './_pages/checkup/relatorios/relatorios.component';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';

const materialModules = [
    MatExpansionModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatDatepickerModule,
    // MatMomentDateModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatRadioModule,
    MatProgressBarModule,
    MatSelectModule,
    //MatFileUploadModule,
    MatCheckboxModule ,
    MatChipsModule,
    MatTreeModule    
];

@NgModule({
    imports: [
        BrowserModule,
        // UploadModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRouting,
        RouterModule,
        materialModules,
        FlexLayoutModule,
        BrowserAnimationsModule,
        ChartAllModule,
        AccumulationChartAllModule,
        RangeNavigatorAllModule,
        ToastrModule.forRoot({
            //timeOut: 25000,
            positionClass: 'toast-top-right',
            enableHtml: true,
            disableTimeOut: true
          }),
        GridModule,
        MenuModule,
        ToolbarModule,
        ScrollDispatchModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        RichTextEditorAllModule,
        DialogModule,
        TreeViewModule,
        ContextMenuModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        ListaFormulariosComponent,
        GrupoPerguntasComponent,
        LoginComponent,
        RegisterComponent,
        RegistrosFormularioComponent,
        LoginPageComponent,
        ListaRegistroComponent,
        ListaDetalheRegistroComponent,
        DetalhamentoRegistroComponent,
        RegisterPageComponent,
        HeaderComponent,
        HomePageComponent,
        PanalCollapseComponent,
        PopupMessagesComponent,
        UploadComponent,
        VendasChatComponent,
        VendasPresencialComponent,
        VendasTelefoneComponent,
        GraficosComponent,
        GraficoMelhorAvaliacaoComponent,
        GraficoVolumetriaAvaliacaoComponent,
        GraficoMetaRealizadoPrevistoComponent,
        GraficoVolumetriaTipoCheckupComponent,
        MenuGraficoComponent,
        PrincipalGraficoComponent,
        HomePageCheckupComponent,
        HomeCheckupComponent,
        HomePageMovComponent,
        PopPageMovComponent,
        HomeMovComponent,
        TextEditorMovComponent,
        SummaryMovComponent,
        HeaderMovComponent,
        PopMovComponent,
        RelatoriosComponent,
        RelatorioExcelCompletoComponent,
    ],

    providers: [
        CategoryService, 
        LegendService, 
        TooltipService, 
        DataLabelService, 
        LineSeriesService, 
        ColumnSeriesService,
        BarSeriesService,
        PieSeriesService,
        RadarSeriesService,
        {
            provide: Compiler
        },
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        SettingsService,
        // provider used to create fake backend
        //fakeBackendProvider
        PageService,
        SortService,
        FilterService,
        GroupService,
        ExcelExportService,
        ToolbarService,
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        DatePipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }