import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CheckupService } from '../../../_services';
import { CheckupDetalhamento } from '../../../_models';
import { PopupMessagesComponent } from '../../popup-messages/popup-messages.component';
import { Message, User } from '../../../_models';
import { Router } from '@angular/router';

/**
 * @title Dynamic grid-list
 */
@Component({
  selector: 'app-detalhamento-registro',
  templateUrl: './detalhamento-registro.component.html',
  styleUrls: ['./detalhamento-registro.component.css']
})

export class DetalhamentoRegistroComponent implements OnInit {
  currentUser: User;
  messageModel: Message;
  detalhamentoFormulario: CheckupDetalhamento;
  loadProtocolos = true;

  constructor(
    private apiCheckup: CheckupService,
    private dialog?: MatDialog,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }


  ngOnInit() {
    let detalhamentoID = JSON.parse(localStorage.getItem('detalhamentoForm'));
    this.apiCheckup.getDetalhamento(detalhamentoID)
      .subscribe(
        rtn => this.detalhamentoFormulario = rtn.data,
        err => {
          this.messageModel = new Message(
            "Registro Formulario",
            "Erro de conexão",
            "Ocorreu um erro ao listar os registros de formulario da base. Tente novamente.",
            "warning")

          this.dialog.open(PopupMessagesComponent, { data: this.messageModel });
          this.loadProtocolos = false;
        },
        () => {
        }
      );
  }
}