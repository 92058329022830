import { CheckupListagem } from '../_models/CheckupListagem';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'
import { CheckupDetalhamento } from '../_models';

@Injectable({
    providedIn: 'root'
})

export class CheckupService {

    getListagem(dataInicial: string, dataFinal: string): Observable<any> {
        return this.http.get<CheckupListagem[]>(`${environment.apiUrl}/Checkup/GetListagem/${dataInicial}/${dataFinal}`);
    }

    getDetalhamento(id: number): Observable<any> {
        return this.http.get<CheckupDetalhamento[]>(`${environment.apiUrl}/Checkup/GetDetalhamento/${id}`);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) {
            
         }
}
