﻿import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'pop-mov',
    templateUrl: 'pop-mov.component.html',
    styleUrls: ['pop-mov.component.css']
})

export class PopMovComponent implements OnInit {

    /* Propriedades */

    /* Construtor */
    constructor() {
    }

    /* Métodos privados */

    /* Métodos públicos */

    /* Eventos */
    ngOnInit() {
    }
}
