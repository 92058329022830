import { Funcionario } from '../_models/Funcionario';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})

export class FuncionarioService {

    getGerentesByRegional(idRegional: string): Observable<any> {
        return this.http.get<Funcionario[]>(`${environment.apiUrl}/Funcionario/GetGerentesByRegional/`+idRegional);
    }

    getGerentesByLoja(idLoja: string): Observable<any> {
        return this.http.get<Funcionario[]>(`${environment.apiUrl}/Funcionario/GetGerentesByLoja/`+idLoja);
    }

    getFuncionarioLogadoPodeVisualizarDiretor(): Observable<any> {
        return this.http.get<boolean>(`${environment.apiUrl}/Funcionario/GetFuncionarioLogadoPodeVisualizarDiretor`);
    }

    getFuncionarioLogadoPodeEditarPop(): Observable<any> {
        return this.http.get<boolean>(`${environment.apiUrl}/Funcionario/GetFuncionarioLogadoPodeEditarPop`);
    }

    getDiretores(): Observable<any> {
        return this.http.get<Funcionario[]>(`${environment.apiUrl}/Funcionario/GetDiretores`);
    }

    getCorretoresByLoja(idLoja: string): Observable<any> {
        return this.http.get<Funcionario[]>(`${environment.apiUrl}/Funcionario/GetCorretoresByLoja/`+idLoja);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) { }
}
