import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSort } from '@angular/material';
import { CheckupService } from '../../../_services';
import { CheckupListagem } from '../../../_models';
import { PopupMessagesComponent } from '../../popup-messages/popup-messages.component';
import { Message, User } from '../../../_models';
import { Router } from '@angular/router';
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { GridComponent, ToolbarItems, Column, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { FormGroup, FormControl} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


L10n.load({
  'pt-BR': {
    grid: {
      EmptyRecord: "Não há registros a serem exibidos",
      GroupDropArea: "Arraste um cabeçalho de coluna aqui para agrupar sua coluna",
      DeleteOperationAlert: "Nenhum registro selecionado para operação de exclusão",
      EditOperationAlert: "Nenhum registro selecionado para operação de edição",
      SaveButton: "Salvar",
      OKButton: "Ok",
      CancelButton: "Cancelar",
      EditFormTitle: "Detalhes",
      AddFormTitle: "Adicionar novas informações",
      GroupCaptionFormat: "{{:headerText}}: {{:key}} - {{:count}} {{if count == 1 }} item {{else}} itens {{/if}} ",
      BatchSaveConfirm: "Tem certeza de que deseja salvar as alterações?",
      BatchSaveLostChanges: "As alterações não salvas serão perdidas. Você tem certeza que quer continuar?",
      ConfirmDelete: "Tem certeza de que deseja excluir o Registro?",
      CancelEdit: "Tem certeza de que deseja cancelar as alterações?",
      PagerInfo: "{0} de {1} páginas ({2} itens)",
      FrozenColumnsViewAlert: "As colunas congeladas devem estar na área de visualização em grade",
      FrozenColumnsScrollAlert: "Ativar allowScrolling ao usar colunas congeladas",
      FrozenNotSupportedException: "Colunas e linhas congeladas não são suportadas para Agrupamento, Modelo de Linha, Modelo de Detalhes, Grade de Hierarquia e Edição de Lote",
      Add: "Adicionar",
      Edit: "Editar",
      Delete: "Excluir",
      Update: "Atualizar",
      Cancel: "Cancelar",
      Done: "Concluído",
      Columns: "Colunas",
      SelectAll: "(Selecionar tudo)",
      PrintGrid: "Impressão",
      Excelexport: "Exportar para Excel",
      Wordexport: "Exportar para Word",
      Pdfexport: "Exportar para PDF",
      StringMenuOptions: [{ text: "Inicia com", value: "StartsWith" }, { text: "Termina com", value: "EndsWith" }, { text: "Contém", value: "Contains" }, { text: "Igual", value: "Equal" }, { text: "Diferente", value: "NotEqual" }],
      NumberMenuOptions: [{ text: "Menos que", value: "LessThan" }, { text: "Maior que", value: "GreaterThan" }, { text: "Menor ou igual", value: "LessThanOrEqual" }, { text: "Maior ou igual", value: "GreaterThanOrEqual" }, { text: "Igual", value: "Equal" }, { text: "Diferente", value: "NotEqual" }, { text: "Entre", value: "Between" }],
      PredicateAnd: "E",
      PredicateOr: "OU",
      Filter: "Filtro",
      FilterButton: "Filtro",
      FilterMenuCaption: "Valor do filtro",
      FilterMenuFromCaption: "A partir de",
      FilterMenuToCaption: "Para",
      FilterbarTitle: "célula de barra de filtro",
      MatchCase: "Encontrado",
      Clear: "Limpar",
      ClearButton: "Limpar",
      ResponsiveFilter: "Filtro",
      ResponsiveSorting: "Ordenar",
      Search: "Pesquisar",
      NumericTextBoxWaterMark: "Colocar o valor",
      DatePickerWaterMark: "Selecionar data",
      EmptyDataSource: "DataSource não pode estar vazio na carga inicial desde colunas são geradas a partir dataSource utilizando a Geração Automática do Grid",
      ForeignKeyAlert: "O valor atualizado deve ser um valor de chave estrangeira válida",
      True: "Verdadeiro",
      False: "Falso",
      UnGroup: "Clique aqui para desagrupar",
      AddRecord: "Adicionar registro",
      EditRecord: "Editar registro",
      DeleteRecord: "excluir registro",
      Save: "Salvar",
      Grouping: "Agrupar",
      Ungrouping: "Desagrupar",
      SortInAscendingOrder: "Ordenar em ordem ascendente",
      SortInDescendingOrder: "Ordenar por ordem decrescente",
      NextPage: "Próxima página",
      PreviousPage: "Página anterior",
      FirstPage: "Primeira página",
      LastPage: "Última página",
      EmptyRowValidationMessage: "Pelo menos um campo deve ser atualizado",
      NoResult: "Nenhuma equivalência encontrada"
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      totalItemsInfo: '({0} itens)',
      firstPageTooltip: 'Ir para a primeira página',
      lastPageTooltip: 'Ir para a última página',
      nextPageTooltip: 'Ir para a próxima página',
      previousPageTooltip: 'Ir para a página anterior',
      nextPagerTooltip: 'Ir para a próxima página',
      previousPagerTooltip: 'Ir para a página anterior'
    }
  }
});

setCulture('pt-BR');

@Component({
  selector: 'app-registros-formulario',
  templateUrl: './registros-formulario.component.html',
  styleUrls: ['./registros-formulario.component.css']
})

export class RegistrosFormularioComponent implements OnInit {
  currentUser: User;
  messageModel: Message;
  lstRegistros: CheckupListagem[] = [];
  loadProtocolos = true;
  pageSettings: Object;
  filterSettings: Object;
  sortSettings: Object;
  formatoptions: Object;
  locale: Object;
  public dataGrid: CheckupListagem[] = [];
  @ViewChild("grid")
  public grid: GridComponent;
  public formatOptions: object;
  public toolbarOptions: object[];
  dataInicialFiltro: Date;
  dataFinalFiltro: Date;

  constructor(
    private router: Router,
    private apiCheckup: CheckupService,
    private datepipe: DatePipe,
    private toast: ToastrService,
    private dialog?: MatDialog,    
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {    
    this.definirDataInicialFiltro();
    this.definirDataFinalFiltro();   
    this.configurarGrid();
    this.carregarGrid();   
  }

  visualizarCheckup(DetalhamentoID: number) {
    localStorage.setItem('detalhamentoForm', JSON.stringify(DetalhamentoID));
    this.router.navigate([`/checkup/listaDetalheRegistro`]);
  }

  private carregarGrid() {
    var dataInicialFormat = this.converterParaStringFormatada(this.dataInicialFiltro);
    var dataFinalFormat = this.converterParaStringFormatada(this.dataFinalFiltro);
    this.loadProtocolos = true;

    this.apiCheckup.getListagem(dataInicialFormat, dataFinalFormat)
      .subscribe(
        rtmc => this.lstRegistros = rtmc.data,
        err => {
          this.messageModel = new Message(
            "Registro Formulario",
            "Erro de conexão",
            "Ocorreu um erro ao listar os registros de formulario da base. Tente novamente.",
            "warning")

          this.dialog.open(PopupMessagesComponent, { data: this.messageModel });
          this.loadProtocolos = false;
          this.dataGrid = [];
        },
        () => {
          this.loadProtocolos = false;
          this.dataGrid = this.lstRegistros;
        }
      );
  }

  private configurarGrid() {
    this.locale = 'pt-BR';
    this.pageSettings = { pageCount: 15 };
    this.filterSettings = { type: 'CheckBox' };
    this.sortSettings = { sortedColumns: [{ field: "criadoEmVal", direction: "descending" }] };
    this.formatOptions = { type: 'date', format: 'dd/MM/yyyy' };
    this.toolbarOptions = [{ text: 'Exportar para Excel', tooltipText: 'Exportar para Excel', prefixIcon: 'e-excelexport', id: 'excelexport', align: 'Right' }];
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'excelexport') {
      (this.grid.columns[9] as Column).visible = false;

      const excelExportProperties: ExcelExportProperties = {
        fileName: 'RegistrosAcelera.xlsx'
      };
      this.grid.excelExport(excelExportProperties);
    }
  }

  excelExportComplete(): void {
    (this.grid.columns[9] as Column).visible = true;
  }

  filtrarGrid(): void{
    if(this.filtroGridEstaValido()){
      this.carregarGrid();
    }
  }

  private filtroGridEstaValido(): boolean{
    this.fecharMensagens();

    if(!this.campofiltroDataInicioEstaValido()){
      this.exibirMensagemAlerta('A data inicial do filtro deve ser informada!');
      return false;
    }
    if(!this.campofiltroDataFimEstaValido()){
      this.exibirMensagemAlerta('A data final do filtro deve ser informada!');
      return false;
    }
    if(!this.camposFiltroDataEstaoOrdenados()){
      this.exibirMensagemAlerta('A data final deve ser maior ou igual a data inicial!');
      return false;
    }

    return true;
  }

  private campofiltroDataInicioEstaValido(): boolean{
    if(this.dataInicialFiltro != null){
      return true;
    }

    return false;
  }

  private campofiltroDataFimEstaValido(): boolean{
    if(this.dataFinalFiltro != null){
      return true;
    }

    return false;
  }

  private camposFiltroDataEstaoOrdenados(): boolean{
    if (this.dataInicialFiltro.getTime() <= this.dataFinalFiltro.getTime()) {
      return true;
    }

    return false;
  }

  private definirDataInicialFiltro(): void{
    var agora = new Date();
    this.dataInicialFiltro = new Date(agora.setMonth(agora.getMonth()-1));
  }

  private definirDataFinalFiltro(): void{
    var agora = new Date();
    this.dataFinalFiltro = agora;
  }

  private converterParaStringFormatada(data: Date): string{
    return this.datepipe.transform(data, 'yyyy-MM-dd');
  }

  private exibirMensagemAlerta(msgAlerta: string) {    
    this.toast.warning(msgAlerta, "Não foi possível realizar a filtragem!");   
  }

  private fecharMensagens(){
    this.toast.clear();
  }
}