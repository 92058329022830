import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { PopSummary } from "src/app/_models/PopSummary";
import { MovService } from "src/app/_services/mov.service";
import { AlertService, FuncionarioService } from "src/app/_services";
import { PopTituloAcao } from "src/app/_enums/PopTituloAcao";
import { PopSubtituloInsert } from "src/app/_models/PopSubtituloInsert";
import { ToastrService } from 'ngx-toastr';
import { PopTituloPrincipalInsert } from "src/app/_models/PopTituloPrincipalInsert";
import { TreeViewComponent, BeforeOpenCloseMenuEventArgs, MenuEventArgs, MenuItemModel, ContextMenuComponent, NodeEditEventArgs, NodeKeyPressEventArgs, NodeClickEventArgs } from "@syncfusion/ej2-angular-navigations";
import { PopTituloUpdate } from "src/app/_models/PopTituloUpdate";
import { Router } from "@angular/router";

@Component({
  selector: 'summary-mov',
  templateUrl: './summary-mov.component.html',
  styleUrls: ['./summary-mov.component.css']
})

export class SummaryMovComponent implements OnInit, OnDestroy {

  /* Propriedades */
  @ViewChild('treeView') treeView: TreeViewComponent;
  @ViewChild('contMenu') contMenu: ContextMenuComponent;
  private sumaryNodes: PopSummary[];
  public funcionarioLogadoPodeEditar = false;
  public acaoTituloPrincipal = PopTituloAcao.Visualizacao;
  public loading = false;
  public field: Object;
  public cssClass: string = 'mytree';
  public menuItems: MenuItemModel[] = [];


  /* Construtor */
  constructor(
    private alertService: AlertService,
    private apiMovService: MovService,
    private apiFuncionarioService: FuncionarioService,
    private toast: ToastrService,
    private router: Router
  ) { }


  /* Métodos privados */
  private carregarSummaryNodes(): void {
    this.loading = true;
    this.apiMovService.getPopSummary()
      .subscribe(
        rtmc => this.sumaryNodes = rtmc.data,
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
        , () => {
          this.loading = false;
          this.field =
          {
            dataSource: this.sumaryNodes,
            id: 'id',
            text: 'title',
            child: 'children',
            ehSubtitulo: 'ehSubtitulo',
            idPopConteudo: 'idPopConteudo',
            idPai: 0
          };
        });
  }
  private tratarPermissaoEdicaoFuncionLogado(): void {
    this.loading = true;
    this.apiFuncionarioService.getFuncionarioLogadoPodeEditarPop()
      .subscribe(
        rtmc => this.funcionarioLogadoPodeEditar = rtmc.data,
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
        , () => {
          this.loading = false;
          if (this.funcionarioLogadoPodeEditar) {
            this.menuItems = [
              { text: 'Add novo item' },
              { text: 'Renomear item' },
              { text: 'Remover item' }
            ];
          }
        });
  }
  private salvarSubtitulo(id: number, idPai: number, nome: string): void {
    this.fecharMensagens();
    let popSubtituloInsert = new PopSubtituloInsert(nome, idPai);
    this.loading = true;
    this.apiMovService.postSubtitulo(popSubtituloInsert)
      .subscribe(
        data => {
          this.loading = false;
          if (data.success) {
            let commandResult = data.data;
            if (commandResult.success) {
              this.exibirMensagemSucesso("O subtítulo foi inserido com sucesso!");
              this.carregarSummaryNodes();
            }
            else {
              this.treeView.removeNodes([id.toString()]);
              let msgsErro = this.obterMensagemFormatada(commandResult.data)
              this.exibirMensagemAlerta(msgsErro, "Não foi possível inserir o subtítulo!");
            }
          }
        });

  }
  private salvarTituloPrincipal(id: number, nome: string): void {
    this.fecharMensagens();
    let popTituloPrincipalInsert = new PopTituloPrincipalInsert(nome);
    this.loading = true;
    this.apiMovService.postTituloPrincipal(popTituloPrincipalInsert)
      .subscribe(
        data => {
          this.loading = false;
          if (data.success) {
            let commandResult = data.data;
            if (commandResult.success) {
              this.exibirMensagemSucesso("O título foi inserido com sucesso!");
              this.carregarSummaryNodes();
            }
            else {
              this.treeView.removeNodes([id.toString()]);
              let msgsErro = this.obterMensagemFormatada(commandResult.data)
              this.exibirMensagemAlerta(msgsErro, "Não foi possível inserir o título!");
            }
          }
        });

  }
  private editarTitulo(id: number, nome: string): void {
    this.fecharMensagens();
    let popTituloUpdate = new PopTituloUpdate(id, nome);
    this.loading = true;
    this.apiMovService.putTitulo(popTituloUpdate)
      .subscribe(
        data => {
          this.loading = false;
          if (data.success) {
            let commandResult = data.data;
            if (commandResult.success) {
              this.exibirMensagemSucesso("O título/subtítulo foi alterado com sucesso!");
              this.carregarSummaryNodes();
            }
            else {
              let msgsErro = this.obterMensagemFormatada(commandResult.data)
              this.exibirMensagemAlerta(msgsErro, "Não foi possível alterar o título/subtítulo!");
              this.carregarSummaryNodes();
            }
          }
        });

  }
  private removerTitulo(id: string): void {
    this.fecharMensagens();
    this.loading = true;
    this.apiMovService.deleteTitulo(Number(id))
      .subscribe(
        data => {
          this.loading = false;
          if (data.success) {
            let commandResult = data.data;
            if (commandResult.success) {
              this.treeView.removeNodes([id]);
              this.exibirMensagemSucesso("O título/subtítulo foi removido com sucesso!");
              this.carregarSummaryNodes();
            }
            else {
              let msgsErro = this.obterMensagemFormatada(commandResult.data)
              this.exibirMensagemAlerta(msgsErro, "Não foi possível remover o título/subtítulo!");
            }
          }
        });

  }
  private exibirMensagemAlerta(msgAlerta: string, msgTitulo: string) {
    this.toast.warning(msgAlerta, msgTitulo);
  }
  private exibirMensagemSucesso(msgTitulo: string) {
    this.toast.success(msgTitulo);
  }
  private fecharMensagens() {
    this.toast.clear();
  }
  private obterMensagemFormatada(erros: string[]): string {
    var msgFormatada = "<div>Verificar os problemas abaixo: <br />";
    erros.forEach(function (e) {
      msgFormatada += "<br />*" + e;
    });
    msgFormatada += "</div>";

    return msgFormatada;
  }


  /* Métodos públicos */
  public menuclick(args: MenuEventArgs) {
    if (this.funcionarioLogadoPodeEditar) {
      let idNodeSelected: string = this.treeView.selectedNodes[0];
      if (args.item.text == "Add novo item") {
        let nodeSelected: any = this.treeView.getTreeData(idNodeSelected)[0];
        let nodeSelectedEhSubtitulo: boolean = nodeSelected.ehSubtitulo;
        if (!nodeSelectedEhSubtitulo) {
          let idNovoSubtitulo: string = "0";
          let ehSubtitulo: boolean = true;
          let item: { [key: string]: Object } = { id: idNovoSubtitulo, text: "Novo item..", ehSubtitulo: ehSubtitulo, idPai: nodeSelected.id }; //idPai: idPai };
          this.treeView.addNodes([item], idNodeSelected, null);
          this.treeView.beginEdit(idNovoSubtitulo);
        }
      }
      else if (args.item.text == "Remover item") {
        this.removerTitulo(idNodeSelected);
      }
      else if (args.item.text == "Renomear item") {
        this.treeView.beginEdit(idNodeSelected);
      }
    }
  }
  public beforeOpen(args: BeforeOpenCloseMenuEventArgs) {
    if (!this.funcionarioLogadoPodeEditar) {
      args.cancel = true;
    }
  }
  public nodeEdited(args: NodeEditEventArgs) {
    if (this.funcionarioLogadoPodeEditar) {
      let novoTexto = args.newText;
      let argNodeId = args.nodeData.id.toString();
      var currentNode = this.treeView.getTreeData(argNodeId)[0];
      if (Number(argNodeId) == 0) { // Inclusão
        if (currentNode.ehSubtitulo) {
          this.salvarSubtitulo(Number(currentNode.id), Number(currentNode.idPai), novoTexto);
        }
        else {
          this.salvarTituloPrincipal(Number(currentNode.id), novoTexto);
        }
      }
      else if (Number(argNodeId) > 0) { // Edição
        this.editarTitulo(Number(currentNode.id), novoTexto);
      }
    }
  }
  public addTitulo() {
    if (this.funcionarioLogadoPodeEditar) {
      let novoNodeId: string = "0";
      let ehSubtitulo = false;
      let idPai = null;
      let item: { [key: string]: Object } = { id: novoNodeId, name: "Novo item..", ehSubtitulo: ehSubtitulo, idPai: idPai };
      this.treeView.addNodes([item], null, null);
      this.treeView.beginEdit(novoNodeId);
    }
  }
  public nodeCheck(args: NodeKeyPressEventArgs | NodeClickEventArgs): void {
    if (args.node.classList.contains('e-level-2')) {
      if (args.event.which !== 3) {
        let currentNode: any = this.treeView.getTreeData(args.node)[0];
        let ehSubtitulo: boolean = currentNode.ehSubtitulo;
        let idCurrentNode: number = currentNode.id;
        if (ehSubtitulo && idCurrentNode > 0) {
          this.router.navigate([`/mov/pop/` + currentNode.idPopConteudo]);
        }
      }
    }
  }


  /* Eventos */
  ngOnInit() {
    this.fecharMensagens();
    this.tratarPermissaoEdicaoFuncionLogado();
    this.carregarSummaryNodes();
  }
  ngOnDestroy() {
    this.fecharMensagens();
  }

}
