import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';
import { Formulario } from 'src/app/_models';
import { TipoFormulario } from 'src/app/_enums/TipoFormulario';

@Component({
  selector: 'app-vendas-presencial',
  templateUrl: './vendas-presencial.component.html'
})
export class VendasPresencialComponent implements OnInit {

  public form = new Formulario();
  public tipoMenuValue: number = TipoMenu.Checkup;

  constructor() { 
    this.definirProprForm();
  }

  ngOnInit() {
  }

  private definirProprForm(): void {
    this.form.id = TipoFormulario.VendasPresencial;
    this.form.link = TipoFormulario.VendasPresencialRouterLink;
    this.form.descricao = TipoFormulario.VendasPresencialDescription;

    localStorage.setItem('currentForm', JSON.stringify(this.form));
  }

}