import { IdentificadorCadastro } from '../_models/IdentificadorCadastro';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})

export class IdentificadorCadastroService {

    setIdentificadorCadastro(): Observable<any> {
        return this.http.get<IdentificadorCadastro[]>(`${environment.apiUrl}/IdentificadorCadastro/Post/`);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) {
            
         }
}
