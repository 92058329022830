import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RelatorioExcelCompleto } from 'src/app/_models/RelatorioExcelCompleto';
import { Message } from 'src/app/_models/Message';
import { PopupMessagesComponent } from 'src/app/_components/popup-messages/popup-messages.component';
import { RelatorioService } from 'src/app/_services/relatorio.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


@Component({
  selector: 'relatorio-excel-completo',
  templateUrl: './excel-completo.component.html',
  styleUrls: ['./excel-completo.component.css']
})

export class RelatorioExcelCompletoComponent implements OnInit {

  /* Propriedades */
  private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private EXCEL_EXTENSION = '.xlsx';
  public messageModel: Message;
  public loading = false;
  public dataRelatorio: RelatorioExcelCompleto[] = [];
  public dataInicialFiltro: Date;
  public dataFinalFiltro: Date;

  /* Construtor */
  constructor(
    private apiRelatorio: RelatorioService,
    private datepipe: DatePipe,
    private toast: ToastrService,
    private dialog?: MatDialog,
  ) { }

  /* Métodos privados */
  private carregarRelatorioExcelCompleto() {
    var dataInicialFormat = this.converterParaStringFormatada(this.dataInicialFiltro);
    var dataFinalFormat = this.converterParaStringFormatada(this.dataFinalFiltro);
    this.loading = true;

    this.apiRelatorio.getExcelCompleto(dataInicialFormat, dataFinalFormat)
      .subscribe(
        rtmc => this.dataRelatorio = rtmc.data,
        err => {
          this.messageModel = new Message(
            "Relatório Excel Completo",
            "Erro de conexão",
            "Ocorreu um erro ao obter o Relatório Excel Completo. Tente novamente.",
            "warning")

          this.dialog.open(PopupMessagesComponent, { data: this.messageModel });
          this.loading = false;
          this.dataRelatorio = [];
        },
        () => {
          this.loading = false;
          this.exportAsExcelFile(this.dataRelatorio, 'Acelera_relatorio_excel_completo');
          this.exibirMensagemSucesso('O arquivo com o relatório excel completo foi baixado com sucesso.');
        }
      );
  }

  private filtroRelatorioEstaValido(): boolean {
    this.fecharMensagens();

    if (!this.campofiltroDataInicioEstaValido()) {
      this.exibirMensagemAlerta('A data inicial do filtro deve ser informada!');
      return false;
    }
    if (!this.campofiltroDataFimEstaValido()) {
      this.exibirMensagemAlerta('A data final do filtro deve ser informada!');
      return false;
    }
    if (!this.camposFiltroDataEstaoOrdenados()) {
      this.exibirMensagemAlerta('A data final deve ser maior ou igual a data inicial!');
      return false;
    }

    return true;
  }

  private campofiltroDataInicioEstaValido(): boolean {
    if (this.dataInicialFiltro != null) {
      return true;
    }

    return false;
  }

  private campofiltroDataFimEstaValido(): boolean {
    if (this.dataFinalFiltro != null) {
      return true;
    }

    return false;
  }

  private camposFiltroDataEstaoOrdenados(): boolean {
    if (this.dataInicialFiltro.getTime() <= this.dataFinalFiltro.getTime()) {
      return true;
    }

    return false;
  }

  private definirDataInicialFiltro(): void {
    var agora = new Date();
    this.dataInicialFiltro = new Date(agora.setMonth(agora.getMonth() - 1));
  }

  private definirDataFinalFiltro(): void {
    var agora = new Date();
    this.dataFinalFiltro = agora;
  }

  private converterParaStringFormatada(data: Date): string {
    return this.datepipe.transform(data, 'yyyy-MM-dd');
  }

  private exibirMensagemAlerta(msgAlerta: string) {
    this.toast.warning(msgAlerta, "Não foi possível processar o relatório!");
  }

  private exibirMensagemSucesso(msgAlerta: string) {
    this.toast.success(msgAlerta, "Relatório processado com sucesso!");
  }

  private fecharMensagens() {
    this.toast.clear();
  }

  private obterDataFormatadaParaNomearArquivo(): string {
    var agora = new Date();
    return this.datepipe.transform(agora, 'dd-MM-yyyy_HH-mm-ss');
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet.A1.v = "Criado Por";
    worksheet.B1.v = "Criado Em";
    worksheet.C1.v = "Perfil";
    worksheet.D1.v = "Formulário";
    worksheet.E1.v = "Nota";
    worksheet.F1.v = "Duração";
    worksheet.G1.v = "Regional";
    worksheet.H1.v = "Loja";
    worksheet.I1.v = "Gerente";
    worksheet.J1.v = "Corretor";
    worksheet.K1.v = "Etapa Aborde Conquistando";
    worksheet.L1.v = "Etapa Conheça o Cliente";
    worksheet.M1.v = "Etapa Encante Na Apresentação";
    worksheet.N1.v = "Etapa Lide Com As Objeções";
    worksheet.O1.v = "Etapa Encerre Sem Medo";
    worksheet.P1.v = "Etapa Relacione-se a Longo Prazo";
    worksheet.Q1.v = "Etapa Amplie Sua Carteira";
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + this.obterDataFormatadaParaNomearArquivo() + this.EXCEL_EXTENSION);
  }

  /* Métodos públicos */
  carregarRelatorioPorFiltro(): void {
    if (this.filtroRelatorioEstaValido()) {
      this.fecharMensagens();
      this.carregarRelatorioExcelCompleto();
    }
  }

  /* Eventos */
  ngOnInit() {
    this.definirDataInicialFiltro();
    this.definirDataFinalFiltro();
  }

}