import { Component, OnInit } from '@angular/core';
import { TipoMenu } from 'src/app/_enums/TipoMenu';

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html'
})
export class GraficosComponent implements OnInit {

  public tipoMenuValue: number = TipoMenu.Checkup;

  constructor() { }

  ngOnInit() {
  }
}