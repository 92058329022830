export class Message {
  constructor(_title: string, _subtitle: string, _message: string, _type: string){
    this.title = _title;
    this.subtitle = _subtitle
    this.message = _message;
    this.type = _type;
  }
  
  title: string;
  subtitle: string;
  message:string;
  type: string;
}