import { Regional } from '../_models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import {environment} from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})

export class RegionalService {

    getRegional(): Observable<any> {
        return this.http.get<Regional[]>(`${environment.apiUrl}/Regional/GetAll`);
    }

    getRegionalUnica(id: number): Observable<any> {
        return this.http.get<Regional[]>(`${environment.apiUrl}/Regional/GetById/`+id);
    }

    getByPerfilLogin(): Observable<any> {
        return this.http.get<Regional[]>(`${environment.apiUrl}/Regional/GetByPerfilLogin`);
    }

    constructor(private http: HttpClient,
        private alertService: AlertService) { }


}
